import { Component, OnInit } from '@angular/core';

import { Auth0Service } from '../auth0.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css']
})
export class CallbackComponent implements OnInit
{

  constructor
  (
    private auth0: Auth0Service
  ){}

  ngOnInit()
  {
    this.auth0.handleAuthCallback();
  }

}
