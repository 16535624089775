import { Component } from '@angular/core';

import { Store } from '@ngrx/store';
import { sidebarActive } from 'src/app/appState/app.actions';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent
{

  constructor
  (
    private store: Store,
  )
  {
    this.store.dispatch(sidebarActive( {value:false}))
  }
}
