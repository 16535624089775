import { createSelector, createFeatureSelector } from '@ngrx/store';
import { TasksState } from './tasks.state';


export const getTasksState = createFeatureSelector('tasksState')

export const tasksState = createSelector(
  getTasksState,
  (tasksState: TasksState) => tasksState
)


