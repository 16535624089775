<div class="samPane">
  <div class="samPaneHeader">
    <h4 class="samPaneTitle">
      <span class="mr-1" *ngIf="taskId ==0">Project Activity</span>
      <span class="mr-1" *ngIf="taskId !=0">Task Activity</span>
      <a href="https://docs.taskquark.com/activity.html" target="TQ-docs">
        <fa-icon [icon]="faQuestionCircle" class="TQ-help-icon" title="Help page"></fa-icon>
      </a>
    </h4>
  </div>

  <!-- <div *ngIf="!canAddActivity"
    class="alert alert-danger" role="alert">
    You have reached the maximum number of tasks for your subscription plan.
  </div>

  <div *ngIf="!canEditactivity"
    class="alert alert-danger" role="alert">
    You do not have permissions to modify this task.
  </div> -->

  <div class="ml-2 mr-2">

    <!-- ACTIVITY HEADER  -->

    <div>
      <label for="ProjectCode" 
        class="TQ-checkbox-label mt-3 ml-2 mr-2">
        Project:
      </label>
      <input id="ProjectCode"
        type="Text" 
        [disabled]="true"
        class="ml-2 mt-1 mb-1 pl-1 pr-1" 
        [ngClass]="{'col-sm-3' : this.samApp.onMobile, 'col-2' : !this.samApp.onMobile}" 
        [(ngModel)]="projectCode">
      <input id="ProjectTitle"
        type="Text" 
        [disabled]="true"
        class="ml-2 mt-1 mb-1 pl-1 pr-1" 
        [ngClass]="{'col-sm-9' : this.samApp.onMobile, 'col-8' : !this.samApp.onMobile}" 
        [(ngModel)]="projectTitle">

      <br>

      <div *ngIf="taskId != 0">
        <label for="Tasktitle" 
        class="TQ-checkbox-label mt-3 ml-2 mr-2">
        Task:
      </label>
      <input id="TaskTitle"
        type="Text" 
        [disabled]="true"
        class="ml-2 mt-1 mb-1 pl-1 pr-1" 
        [ngClass]="{'col-sm-12' : this.samApp.onMobile, 'col-11' : !this.samApp.onMobile}" 
        [(ngModel)]="taskTitle">
      </div>
    </div>

    <!-- ACTIVITY TAB  -->

    <mat-tab-group 
      class="mt-3"
      animationDuration="333ms"
      [mat-stretch-tabs]=false
      [selectedIndex]="selectedTab">
  
      <!-- SUMMARY TAB -->

      <!-- <mat-tab label="Summary"> 
        <div class="mt-3 ml-3 mr-3">
          <mat-card>
            <mat-card-header class="mb-2">
              <mat-card-title>Task Activity</mat-card-title>
              <mat-card-subtitle>This task had this activity:</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <div class="row mt-2 ml-1">
                <div class="ml-1"><b>Number of activity records:</b></div>
                <div class="ml-2">{{activities_number}}</div>
              </div>
              <div class="row mt-2 ml-1">
                <div class="ml-1"><b>Total time:</b></div>
                <div class="ml-2">{tqDT.minutesToDuration(activities_time)}}</div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </mat-tab> -->

      <!-- REGISTRY TAB -->

      <mat-tab label="Registry"> 

        <mat-card class="m-2">
          <mat-card-content>

            <div style="display:inline-block">
              <label for="ActivityStartDatePicker" 
                class="TQ-checkbox-label mt-3 ml-2 mr-2">
                Start Date:
              </label>
              <tq-date-widget #ActivityStartDatePicker
                [maxDate]="activityEndDate"
                [(date)]="activityStartDate"
              ></tq-date-widget>  
              <button 
                class="badge TQ-clear-button ml-1" 
                (click)="clearActivityStartDate()">
                X
              </button>
            </div>
            <div *ngIf="activityStartDate" 
              style="display:inline-block">
              <tq-time-widget class="ml-3"  
                [(time)]="activityStartTime"
                [timeFormat]=this.appState.prefLocTimeFormat
              ></tq-time-widget>
              <button 
                class="badge TQ-clear-button ml-1" 
                (click)="clearActivityStartTime()">
                X
              </button>
            </div>
    
            <br>
    
            <label for="activityDescription" 
              class="TQ-checkbox-label mt-2 ml-2 mr-1">
              Description:
            </label>
            <input id="activityDescription" 
              type="text" 
              class="ml-2 mt-1 mb-1 pl-1 pr-1" 
              [ngClass]="{'col-sm-12' : this.samApp.onMobile, 'col-8' : !this.samApp.onMobile}" 
              [(ngModel)]="activityDescription"
            >
    
            <label 
              class="TQ-checkbox-label mt-2 ml-3 mr-2">
              Time:
            </label>
            <tq-duration-widget 
              [(duration)]="activityDuration"
            ></tq-duration-widget>
            <button class="badge TQ-clear-button ml-1" 
              (click)="clearActivityDuration()">
              X
            </button>
    
            <br>
    
            <div style="display:inline-block">
              <label for="ActivityEndDatePicker" 
                class="TQ-checkbox-label mt-3 ml-2 mr-2">
                End Date:&nbsp;
              </label>
              <tq-date-widget #ActivityEndDatePicker
                [minDate]="activityStartDate"
                [(date)]="activityEndDate"
              ></tq-date-widget>  
              <button 
                class="badge TQ-clear-button ml-1" 
                (click)="clearActivityEndDate()">
                X
              </button>
            </div>
            <div *ngIf="activityEndDate" 
              style="display:inline-block">
              <tq-time-widget class="ml-3"  
                [(time)]="activityEndTime"
                [timeFormat]=this.appState.prefLocTimeFormat
              ></tq-time-widget>
              <button 
                class="badge TQ-clear-button ml-1" 
                (click)="clearActivityEndTime()">
                X
              </button>
            </div>
  
            <hr>
  
            <button id="ActivitySaveButton"
              title="Add Activity" 
              class="btn btn-success ml-2" 
              [disabled]="!canAddActivity || this.activityStartDate == null" 
              (click)="editing=false; addActivity()"
            >
              {{ editing ? "Save" : "Add" }}
            </button>
            <button id="ActivityCancelButton"
              title="Cancel Activity" 
              class="btn btn-warning ml-2" 
              (click)="editing=false; cancelActivity()"
            >
              Cancel
            </button>
            <button id="ActivityDuplicateButton"
              *ngIf="editing" 
              title="Duplicate Activity" 
              class="btn btn-success ml-3" 
              [disabled]="!canAddActivity || this.activityStartDate == null" 
              (click)="editing=false; duplicateActivity()"
            >
              Duplicate
            </button>
            <button id="ActivityDeleteButton"
              *ngIf="editing" 
              title="Delete Activity" 
              class="btn btn-danger ml-5" 
              style="float:right"
              [disabled]="!canEditActivity" 
              (click)="editing=false; confirmDeleteActivity()"
            >
              Delete
            </button>
  
          </mat-card-content>
        </mat-card>

        <!-- ACTIVITY REGISTRY  -->

        <mat-card class="m-0">
          <mat-card-header class="mt-3 mb-2">
            <mat-card-title>
              <span class="mr-1" *ngIf="taskId ==0">Project Activity Registry:</span>
              <span class="mr-1" *ngIf="taskId !=0">Task Activity Registry:</span>
            </mat-card-title>
          </mat-card-header>

          <mat-card-content>

            <div class="row m-2">
              <div class="ml-1"><b>Number of records:</b></div>
              <div class="ml-2">{{activities_number}}</div>
              <div class="ml-4"><b>Total time:</b></div>
              <div class="ml-2">{{tqDT.minutesToDuration(activities_time)}}</div>
            </div>


            <p-table 
              [value]="activities" 
              styleClass="p-datatable-gridlines"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th class="p-2" style="width: 10%" 
                    pSortableColumn="startDate">
                    Start
                    <p-sortIcon field="startDate"></p-sortIcon>
                  </th>
                  <th class="p-2" style="width: 10%">Time</th>
                  <th class="p-2" style="width: 10%">End</th>
                  <th class="p-2" >Description</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-act>
                <tr 
                  [class.table-success]="(act.id==this.activityId)"
                  (click)="editActivity(act.id)"          
                >
                  <td class="p-1">
                    {{ tqDT.dateToTZ(act.startDate, act.startTime) }} 
                    {{ tqDT.formatTimeFromISO(act.startDate, tqDT.timeToTZ(act.startDate, act.startTime)) }}
                  </td>
                  <td class="p-1">
                    <span *ngIf="act.value && act.value !== 0">{{ tqDT.minutesToDuration(act.value) }}</span>
                  </td>
                  <td class="p-1">
                    {{ tqDT.dateToTZ(act.endDate, act.endTime) }} 
                    {{ tqDT.formatTimeFromISO(act.endDate, tqDT.timeToTZ(act.endDate, act.endTime)) }}
                  </td>
                  <td class="p-1">
                    {{ act.description }}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </mat-card-content>
        </mat-card>

      </mat-tab>

    </mat-tab-group>

  </div>

  <p-confirmDialog #cd
    key="deleteActivity"
    [style]="{width: '33vw'}" 
    [breakpoints]="{'960px': '75vw', '640px': '95vw'}"
    [baseZIndex]="10000"
    focusTrap="true"
    closeOnEscape="true">
    <ng-template pTemplate="header">
      <h3>Delete Activity</h3>
    </ng-template>
    <ng-template pTemplate="message" let-message>
      <div class="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
          Are you sure you want to delete this activity?
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button type="button" class="btn btn-warning ml-2" (click)="cd.reject()">Cancel</button>
        <button type="button" class="btn btn-danger ml-2" (click)="cd.accept()">Delete</button>
    </ng-template>
  </p-confirmDialog>

  <div *ngIf="this.samApp.onMobile">
    <br>
    <br>
    <br>
    <br>
    <br>
  </div>

</div>
