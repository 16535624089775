import { createReducer, on } from '@ngrx/store';

import { initialNotesState } from './notes.state';
import * as NOTES_ACTIONS from './notes.actions';


export const notesReducer = createReducer(
  initialNotesState,

  // NOTEPAD
  on(NOTES_ACTIONS.selectNote, (state, { id }) => ({
    ...state,
    selectedNoteId: id,
  })),

  on(NOTES_ACTIONS.loadNotesList, (state) => ({
    ...state,
    status: 'loading',
    error: null,
  })),
  on(NOTES_ACTIONS.loadNotesListOK, (state, { notes }) => ({
    ...state,
    TQnotes: notes,
    status: 'loaded',
    error: null
  })),  
  on(NOTES_ACTIONS.loadNotesListKO, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error,
  })),  

);