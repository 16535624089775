<header id="header">
  <nav class="navbar navbar-expand-sm navbar-dark p-0 bg-taskquark-blue">

    <div class="container-fluid"
      [ngClass]="{'navbar-short': !samApp.onMobile && !loggedIn }">

      <img class="TQ-logo_icon" src="../assets/img/taskquark-logo_icon.png" />
      
      <!-- Environment tag -->
      <a *ngIf="!loggedIn"
        class="navbar-brand TQ-brand" 
        href="https://www.taskquark.com">
        <img class="TQ-logo_name" src="../assets/img/taskquark-logo_name.png" alt="TaskQuark" />
        <span *ngIf="this.samApp.getEnvironment() != 'pro'">
          ({{this.samApp.getEnvironment()}})
        </span>
      </a>
      <a *ngIf="loggedIn && !samApp.onMobile"
        class="navbar-brand TQ-brand">
        <img class="TQ-logo_name" src="../assets/img/taskquark-logo_name.png" alt="TaskQuark" />
        <span *ngIf="this.samApp.getEnvironment() != 'pro'">
          ({{this.samApp.getEnvironment()}})
        </span>
      </a>

      <!-- Login button only for mobile outside hamburguer menu -->
      <a *ngIf="samApp.onMobile && loggedIn"
        class="btn btn-primary text-white mr-3"
        [routerLink]="['profile']" 
        [queryParams]="{ tab: 'configuration' }">
        {{ this.email }}</a>
      <!-- Profile Configuration Widget -->
      <!-- <tq-profile-widget *ngIf="samApp.onMobile && loggedIn"
        class="profile-widget"
      ></tq-profile-widget> -->

      <!-- Mobile Hamburguer menu -->
      <button type="button"
        class="navbar-toggler"
        [ngClass]="samApp.onMobile ? 'ml-auto mr-0; p-2' : 'mr-auto'"  
        data-toggle="collapse" 
        data-target="#navbarResponsive"
        aria-controls="navbarResponsive" 
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div id="navbarResponsive"
        class="collapse navbar-collapse"
        [ngClass]="samApp.onMobile ? 'ml-2' : 'ml-0'">

        <ul class="navbar-nav ml-auto">
          <!-- Sidebar options for mobile menu -->
          <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
            <a *ngIf="loggedIn && samApp.onMobile"
            class="navbar-brand" routerLink="/todopad">Todopad</a>
          </li>
          <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
            <a *ngIf="loggedIn && samApp.onMobile"
            class="navbar-brand" routerLink="/weekpad">Weekpad</a>
          </li>
          <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
            <a *ngIf="loggedIn && samApp.onMobile"
            class="navbar-brand" routerLink="/monthpad">Monthpad</a>
          </li>
          <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
            <a *ngIf="loggedIn && samApp.onMobile"
            class="navbar-brand" routerLink="/workpad">Workpad</a>
          </li>
          <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
            <a *ngIf="loggedIn && samApp.onMobile"
                class="navbar-brand" routerLink="/notepad">Notepad</a>
          </li>
          <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
            <a *ngIf="loggedIn && samApp.onMobile"
                class="navbar-brand" routerLink="/projectpad">Projects</a>
          </li>

          <li>
            <a *ngIf="!samApp.onMobile && loggedIn && this.getEmail()"
              class="btn btn-primary text-white mr-3"
              [routerLink]="['profile']" [queryParams]="{ tab: 'configuration' }">
            {{ this.getEmail() }}</a>             
          </li>
          <!-- tq-profile-widget *ngIf="!samApp.onMobile && loggedIn"
            class="profile-widget"
          ></tq-profile-widget> -->

          <li *ngIf="loggedIn"
            class="nav-item"
            [ngClass]="samApp.onMobile ? 'pb-2' : 'pb-0'"
            data-toggle="collapse" 
            data-target=".navbar-collapse.show">
            <a class="btn btn-success text-white"
              [routerLink]="['logout']">Logout</a>
          </li>
          <li *ngIf="!loggedIn"
            class="nav-item"
            [ngClass]="samApp.onMobile ? 'pb-2' : 'pb-0'">
            <a class="btn btn-success text-white"
              (click)="doLogin()">Login</a>
          </li>
        </ul>
      </div>

    </div>
  </nav>
</header>
