<div class="samPane">
  <div class="samPaneHeader">
    <h4 class="samPaneTitle">Loading...
      <a href="https://docs.taskquark.com/support.html" target="TQ-docs">
        <fa-icon [icon]="faQuestionCircle" class="TQ-help-icon" title="Help page"></fa-icon>
      </a>
    </h4>
    <fa-icon [icon]="faSpinner" [spin]="true" [pulse]="true" [size]="'lg'"
      class="samPaneTitle">
    </fa-icon>
  </div>
</div>
