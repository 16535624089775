import { environment } from 'src/environments/environment';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Auth0Client } from '@auth0/auth0-spa-js';

import { SamuraiService } from 'src/services/samurai/samurai.service';

@Injectable({
  providedIn: 'root'
})
export class Auth0Service
{
  
  client = new Auth0Client({
    domain: environment.auth0.domain,
    clientId: environment.auth0.client_id,
    authorizationParams: {
      audience: environment.auth0.audience,
      redirect_uri: `${window.location.origin}/callback`,
      useRefreshTokens: environment.auth0.useRefreshTokens,
      cacheLocation: 'localstorage',  // environment.auth0.localstorage ? 'localstorage' : 'memory',
      scope: 'openid profile email',
    },
  });

  constructor
  (
    private router: Router,
    private samApp: SamuraiService,
  )
  {
    this.samApp.debug("AUTH0 loading...")
  }

  handleAuthCallback()
  {
    this.client.handleRedirectCallback()
      .then (result => { 
        // Authentication has been successfully completed
        this.samApp.debug("Auth0Service.handleAuthCallback OK: result: " + JSON.stringify(result))
        const targetUrl = result.appState?.target || '/';
        this.router.navigate([targetUrl]);
      })
      .catch (error => {        
        // Handle errors during authentication
        this.samApp.debug("Auth0Service.handleAuthCallback KO: error: " + error)
        this.router.navigate(['/logout']);
      })
  }
  
  async isAuthenticated() 
  {
    let res = await this.client.isAuthenticated();
    //this.samApp.debug("Auth0Service.isAuthenticated: " + res);
    return res;
  }
  
  async getUser()
  {
    let user = await this.client.getUser();
    this.samApp.debug("Auth0Service.getUser: " + JSON.stringify(user));
    return user;
  }

  async getTokenSilently(options?)
  {
    let token = await this.client.getTokenSilently(options);
    this.samApp.debug("Auth0Service.getTokenSilently: " + token);
    return token
  }

  async signup(redirectPath: string = '/')
  {
    await this.client.loginWithRedirect({
      appState: { target: redirectPath },
      authorizationParams: {
        redirect_uri: `${window.location.origin}/callback`,
        screen_hint: 'signup'
      },
    });

  }

  async signin(redirectPath: string = '/')
  {
    await this.client.loginWithRedirect({
      appState: { target: redirectPath },
      authorizationParams: {
        redirect_uri: `${window.location.origin}/callback`,
        screen_hint: 'login'
      },
    });    
  }

  async logout()
  {
    await this.client.logout({
      logoutParams: {
        returnTo: window.location.origin
      }
    });
  }

}
