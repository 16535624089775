<div id="notepadPane" class="samPane">
  <div class="samPaneHeader"
    [ngClass]="{'TQ-header-filtered' : notepadFilter}">
    <h4 class="samPaneTitle">
      Notepad
      <a href="https://docs.taskquark.com/notepad.html" target="TQ-docs">
        <fa-icon [icon]="faQuestionCircle" class="TQ-help-icon" title="Help page"></fa-icon>
      </a>
    </h4>
    <div *ngIf="appState.projectFilterCode!= ''">
      <p class="samPaneSubtitle"
        [ngClass]="'TQ-'+appState.projectFilterColor">
        {{appState.projectFilterCode}}
      </p>
      <button 
        class="samPaneSubtitle badge TQ-clear-button mt-3"
        title="Clear (Esc)"
        (click)="clearProjectFilter()">X
      </button>
    </div>
    <fa-icon *ngIf="notesState.status!='loaded'"
      [icon]="faSpinner" [spin]="true" [pulse]="true" [size]="'lg'"
      class="samPaneTitle">
    </fa-icon>
    <div class="TQ-filter-area">
      <button class="TQ-filter-button"
        (click)="notepadFilter=!notepadFilter; clearNotepadFilter();">
        <fa-icon [icon]="faFilter" class="fa-lg" title="Filter Notepad (Ctrl-F)"></fa-icon>
      </button>
      <input id="NotepadFilter" type="text" class="pl-1"
        [hidden]="!notepadFilter"
        [(ngModel)]="this.notepadFilterRegEx">
      <button class="badge TQ-clear-button ml-1 mr-2"
        title="Clear (Esc)"
        [hidden]="!notepadFilter"
        (click)="clearNotepadFilter()">X</button>
    </div>
  </div>

  <div>
    <button *ngIf="noteId > 0"
      class="btn btn-sm btn-info TQ-scroll-top-button"
      (click)="clearToTop()">
      <fa-icon [icon]="faArrowUp" title="Scroll to top"></fa-icon>
    </button>
    <button *ngIf="canAddNote"
        class="btn btn-sm btn-success TQ-add-button"
        (click)="editNote()">
        <fa-icon [icon]="faPlus" title="Add Note"></fa-icon>
    </button>

    <div *ngIf="!canAddNote"
      class="alert alert-danger" role="alert">
      You have reached the maximum number of notes for your subscription plan.
    </div>

    <p-treeTable 
      [value]="notepadTree"
      [resizableColumns]="true" 
      cdkDropList #ProjectList="cdkDropList"
      (cdkDropListDropped)="drop($event)"
      class="table table-bordered"
    >
      <ng-template pTemplate="header">
          <tr class="m-0">
            <!-- <th ttResizableColumn class="col-0  m-0 p-1 pl-2"></th> -->
            <th ttResizableColumn class="col-12 m-0 p-1 pl-2">Note</th>
          </tr>
      </ng-template>

      <ng-template pTemplate="body" 
        let-rowNode
        let-rowData="rowData"
      >
        <tr id="col1-{{rowData.id}}"
          class="m-0 p-0 project-cursor"
          [class.table-success]="(rowData.id==this.noteId)"
          [class.table-warning]="(rowData.droppable==false)"
          [class.XXXnote-header]="(rowData.code)"
          cdkDrag cdkDragLockAxis="y"
          [cdkDragData]="[rowData]"
          [cdkDragStartDelay]="{touch:400,mouse:0}"
          (cdkDragStarted)="dragStarted($event, rowData)"
          (click)="editRow(rowData)">
          <td class="m-0 p-0 pl-2">
            <p-treeTableToggler 
              class="m-0 p-0" 
              [rowNode]="rowNode">
            </p-treeTableToggler>
            <span *ngIf="rowData.code"
              class="badge TQ-badge TQ-text-break"
              [ngClass]="'TQ-'+rowData.color"
              style="vertical-align: middle">
              {{rowData.code}} - {{rowData.title}}
            </span>

            <span *ngIf="!rowData.code"
              class="task-icon m-0 p-0 ml-n4 pl-2">
              <fa-icon [icon]="faFile" title="Note"></fa-icon>
            </span>
            <span *ngIf="!rowData.code"
              class="m-0 p-0 ml-1 TQ-text-break"
              style="vertical-align: middle">
              {{rowData.title}}
            </span>
          </td>
      </tr>
      </ng-template>
  </p-treeTable>

  </div>
</div>
