import { Component, Input, isStandalone } from '@angular/core';

import { TQDateTimeService } from 'src/app/services/tqdatetime.service';

import { faCaretLeft, faCaretRight, faCaretSquareLeft, faCaretSquareRight, faCaretSquareDown, faCaretSquareUp }
  from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'tq-task-event-date',
  templateUrl: './tq-task-event-date.component.html',
  styleUrls: ['./tq-task-event-date.component.scss']
})
export class TQTaskEventDateComponent {
  faCaretLeft = faCaretLeft;
  faCaretRight = faCaretRight;
  faCaretSquareLeft = faCaretSquareLeft;
  faCaretSquareRight = faCaretSquareRight;
  faCaretSquareUp = faCaretSquareUp;
  faCaretSquareDown = faCaretSquareDown;  

  @Input() eventType: string = "";
  @Input() date: string = "";
  @Input() onDate: string = "";
 
  constructor 
  (
    public tqDT: TQDateTimeService,
  ) 
  {} 


  isDatePast():boolean
  {
    return this.tqDT.isDateBefore(this.date, this.onDate)
  }

  isSameDate():boolean  
  {
    return this.tqDT.isSameDate(this.onDate, this.date)
  }

  isTomorrow():boolean
  {
    return this.tqDT.isTomorrow(this.date, this.onDate)
  }

  isDateFuture():boolean
  {
    return this.tqDT.isDateAfter(this.date, this.onDate)
  }

}
