<div>
  <h4 class="samPaneTitle">Logging out...
    <a href="https://docs.taskquark.com/get-started.html" target="TQ-docs">
      <fa-icon [icon]="faQuestionCircle" class="TQ-help-icon" title="Help page"></fa-icon>
    </a>
  </h4>
  <fa-icon [icon]="faSpinner" [spin]="true" [pulse]="true" [size]="'lg'"
    class="samPaneTitle">
  </fa-icon>
</div>

