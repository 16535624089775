import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { map, mergeMap, switchMap, catchError } from 'rxjs/operators';

import * as NOTES_ACTIONS from './notes.actions';

import { TQApiService } from 'src/app/services/tqapi.service';

@Injectable()
export class NoteEffects {

  constructor(
    private actions$: Actions,
    private tqApiService: TQApiService
  ) { }

  // TQnotes
  loadNotesList$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(NOTES_ACTIONS.loadNotesList),
        switchMap(async () => {
          try 
          {
            const notes = await this.tqApiService.getNoteSamplesByProfile();
            return NOTES_ACTIONS.loadNotesListOK({ notes });
          } 
          catch (error) 
          {
            NOTES_ACTIONS.loadNotesListKO({ error })
          }
        }
          // from(this.tqApiService.getNoteSamplesByProfile())
          //   .pipe(
          //     map( (notes) => NOTES_ACTIONS.loadNotesListOK({ notes })),
          //     catchError( (error) => of(NOTES_ACTIONS.loadNotesListKO({ error })))
          //   )
        )
      )
    )

}