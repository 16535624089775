import { createAction, props } from '@ngrx/store';

import { Task } from 'src/app/models/task';
import { TaskSample } from 'src/app/models/task';

// TQtasks
export const loadTasksList = createAction(
  '[Tasks] Load Tasks',
);
export const loadTasksListOK = createAction(
  '[Tasks] Load Tasks OK',
  props<{ TQtasks: Array<TaskSample> }>()
);
export const loadTasksListKO = createAction(
  '[Tasks] Load Tasks KO',
  props<{ error: string }>()
);

export const selectTask = createAction(
  '[Tasks] Select Task',
  props<{ id: number }>()
);


// TASK
/*
export const loadTask = createAction(
  '[Task] Load Task',
  props<{ id: number }>()
);
export const loadTaskOK = createAction(
  '[Task] Load Task OK',
  props<{ Task: Task }>()
);
export const loadTaskKO = createAction(
  '[Task] Load Task KO',
  props<{ error: string }>()
);

export const addTask = createAction(
  '[Task] Add Task',
  props<{ task: Task }>()
);
export const deleteTask = createAction(
  '[Task] Delete Task',
  props<{ id: number }>()
);
*/
