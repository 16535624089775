<div>
  <app-header></app-header>
</div>
<div class="samPage d-flex"
  (swipeLeft)="onSwipeLeft($event)"
  (swipeRight)="onSwipeRight($event)">
  <div class="samSidebar">
    <app-sidebar></app-sidebar>
  </div>
  <div class="samOutlet">
    <router-outlet></router-outlet>
  </div>
</div>
<div>
  <app-footer></app-footer>
</div>
