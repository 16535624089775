<div *ngIf="(eventType=='target')"
  title="target date"
  [ngClass]="{
    'blue-box'      : isTomorrow(),  
  }">
  <span class="task-icon"
    [ngClass]="{
      'blue'          : isDatePast() || isTomorrow() || isDateFuture(),
      'blue-reversed' : isSameDate() 
    }">
    <fa-icon [icon]="faCaretSquareDown"></fa-icon>
  </span> 
  <span class="task-date"
    [ngClass]="{
      'red'           : isDatePast(),
      'blue'          : isTomorrow(),
      'blue-reversed' : isSameDate(),
      'black'         : isDateFuture(),
    }">
    {{date}} 
  </span>
</div>

<div *ngIf="(eventType=='due')"
  title="due date"
  [ngClass]="{
    'red-box'      : isTomorrow(),  
  }">
  <span class="task-icon"
    [ngClass]="{
      'red'          : isDatePast() || isTomorrow() || isDateFuture(),
      'red-reversed' : isSameDate(),
    }">
    <fa-icon [icon]="faCaretSquareDown"></fa-icon>
  </span>
  <span class="task-date"
    [ngClass]="{
      'red'          : isDatePast() || isTomorrow(),
      'red-reversed' : isSameDate(),
      'black'        : isDateFuture(),
    }">
    {{date}} 
  </span>
</div>

