import { createAction, props } from '@ngrx/store';

import { Note, NoteSample } from 'src/app/models/note';

// TQnotes 
export const loadNotesList = createAction(
  '[Notepad] Load Notes',
);
export const loadNotesListOK = createAction(
  '[TQApi Notes] Load Notes OK',
  props<{ notes: Array<NoteSample> }>()
);
export const loadNotesListKO = createAction(
  '[TQApi Notes] Load Notes KO',
  props<{ error: string }>()
);

export const selectNote = createAction(
  '[Notepad] Select Note',
  props<{ id: number }>()
);


// NOTE 
// export const loadNote = createAction(
//   '[Note] Load Note',
//   props<{ id: number }>()
// );
// export const loadNoteOK = createAction(
//   '[Note] Load Note OK',
//   props<{ note: Note }>()
// );
// export const loadNoteKO = createAction(
//   '[Note] Load Note KO',
//   props<{ error: string }>()
// );

// export const addNote = createAction(
//   '[Note] Add Note',
//   props<{ note: Note }>()
// );
// export const deleteNote = createAction(
//   '[Note] Delete Note',
//   props<{ id: number }>()
// );