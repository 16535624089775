import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { MatDatepickerInputEvent } from '@angular/material/datepicker';

import { DateTime } from 'luxon';

@Component({
  selector: 'tq-date-widget',
  templateUrl: './tq-date-widget.component.html',
  styleUrl: './tq-date-widget.component.scss'
})
export class TQDateWidgetComponent implements OnChanges
{
  @Input() date: DateTime; 
  @Input() minDate: DateTime; 
  @Input() maxDate: DateTime; 
  @Output() dateChange = new EventEmitter<DateTime>();

  constructor() {}

  // Parent component changes 
  ngOnChanges() {} 

  // Local changes 
  changeDate(event: MatDatepickerInputEvent<Date>) 
  {
    this.dateChange.emit(this.date);
  }

}
