import { environment } from '../environments/environment'; 

import { NgModule, Injectable } from '@angular/core';
import { LOCALE_ID } from '@angular/core';
import {
  BrowserModule,
  HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG,
  Title,
  } from '@angular/platform-browser';

import * as Hammer from 'hammerjs';

import { HttpClientModule} from '@angular/common/http'
import { HTTP_INTERCEPTORS } from '@angular/common/http'

import { DateAdapter } from '@angular/material/core';

import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { appReducer } from 'src/app/appState/app.reducers';
import { notesReducer } from './notes/store/notes.reducers';
import { projectsReducer } from './projects/store/projects.reducers';
import { tasksReducer } from './tasks/store/tasks.reducers';
import { AppEffects } from 'src/app/appState/app.effects';
import { NoteEffects } from './notes/store/notes.effects';
import { ProjectsEffects } from './projects/store/projects.effects';
import { TasksEffects } from './tasks/store/tasks.effects';

import { TQDateTimeService, TQDateAdapter } from 'src/app/services/tqdatetime.service'
import { TQApiErrorInterceptor } from 'src/app/services/tqapi.service';
import { CallbackComponent } from 'src/app/services/auth0/callback/callback.component';
import { StripeCallbackComponent } from 'src/app/services/stripe/stripe-callback/stripe-callback.component';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppRoutingGuard } from './app-routing.guard';

import { FooterComponent } from './modules/footer/footer.component';
import { HeaderComponent } from './modules/header/header.component';
import { HomeComponent } from './modules/home/home.component';
import { LoadingComponent } from './modules/loading/loading.component';
import { LogoutComponent } from './modules/logout/logout.component';
import { SigninComponent } from './modules/signin/signin.component';
import { SignupComponent } from './modules/signup/signup.component';
import { SidebarComponent } from './modules/sidebar/sidebar.component';

import { ActivityComponent } from 'src/app/activity/activity/activity.component';
import { ProfileComponent } from 'src/app/profile/profile.component';

import { NotesModule } from 'src/app/notes/notes.module';
import { ProjectsModule } from 'src/app/projects/projects.module';
import { TasksModule } from 'src/app/tasks/tasks.module';

import { SharedModule } from 'src/app/shared/shared.module';

@Injectable()
export class HammerConfig extends HammerGestureConfig
{
  buildHammer(element: HTMLElement): HammerManager
  {
    return new Hammer.Manager(element,
    {
      touchAction: 'auto',
      inputClass: Hammer.TouchInput,
      recognizers: [ [Hammer.Swipe, {
        direction: Hammer.DIRECTION_HORIZONTAL,
        threshold: 20
      }] ]
    });
  }
}


@NgModule({
  declarations: [
    ActivityComponent,
    AppComponent,
    CallbackComponent,
    FooterComponent,
    HeaderComponent,
    HomeComponent,
    LoadingComponent,
    LogoutComponent,
    ProfileComponent,
    SidebarComponent,
    SigninComponent,
    SignupComponent,
    StripeCallbackComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HammerModule,
    HttpClientModule,
    NotesModule,
    ProjectsModule,
    SharedModule,
    TasksModule,
    StoreModule.forRoot({
      appState: appReducer,
      notesState: notesReducer,
      projectsState: projectsReducer,
      tasksState: tasksReducer,
    }, {}),
    StoreDevtoolsModule.instrument({
      maxAge: 25, 
      logOnly: environment.production, 
      connectInZone: true
    }),
    EffectsModule.forRoot([
      AppEffects,
      NoteEffects,
      ProjectsEffects,
      TasksEffects,
    ]),
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'en-US'
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig
    },
    {
      provide: DateAdapter,
      useClass: TQDateAdapter
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TQApiErrorInterceptor,
      multi: true
    },
    AppRoutingGuard,
    Title,
    TQDateTimeService,
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {}
