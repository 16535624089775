import { createSelector, createFeatureSelector } from '@ngrx/store';

import { AppState } from './app.state';


export const getAppState = createFeatureSelector('appState')

export const appState = createSelector(
  getAppState,
  (appState: AppState) => appState
)

