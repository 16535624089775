<select type="checkbox" 
  class="hour-selector"
  [(ngModel)]="hourLabel"
  (change)="changeTime()">
  <option *ngFor="let h of getHourLabels();" value="{{h}}">{{h}}</option>
</select>
<select type="checkbox" 
  class="time-selector"
  [(ngModel)]="minuteLabel"
  (change)="changeTime()">
  <option *ngFor="let m of getMinuteLabels();" value="{{m}}">{{m}}</option>
</select>