import { NgModule } from '@angular/core';

import { SharedModule } from 'src/app/shared/shared.module';

import { NoteComponent } from './note/note.component';
import { NotepadComponent } from './notepad/notepad.component';

@NgModule({
  declarations: [
    NoteComponent,
    NotepadComponent,
  ],
  imports: [
    SharedModule
  ],
})
export class NotesModule { }
