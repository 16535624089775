import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap, map, mergeMap, concatMap } from 'rxjs/operators';

import * as APP_ACTIONS from './app.actions';

import { SamuraiService } from 'src/services/samurai/samurai.service';
import { from } from 'rxjs';

@Injectable()
export class AppEffects {

  constructor
  (
    private actions$: Actions,
    private samApp: SamuraiService,
  ) {}

  initTQapp$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(APP_ACTIONS.initTQapp),
        mergeMap( () => [
          APP_ACTIONS.enterTQpad({
            pad: this.samApp.retrieveStore("TQlastpad", "session"),
          }),
          APP_ACTIONS.projectFiltered({
            id:    this.samApp.retrieveStore("TQprojectFilterId")    || 0,
            code:  this.samApp.retrieveStore("TQprojectFilterCode")  || '',
            color: this.samApp.retrieveStore("TQprojectFilterColor") || '',
            list:  this.samApp.retrieveStore("TQprojectFilterList")  || [],
          }),
        ])
      )
  )
  
  enterTQpad$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(APP_ACTIONS.enterTQpad),
        tap((action) => {
          this.samApp.saveStore("TQlastpad", action.pad, "session");
        }),
        map(() => APP_ACTIONS.setLastGuardTime())
      ) 
    );
      
   onMobile$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(APP_ACTIONS.onMobile),
        map( (action) => APP_ACTIONS.sidebarActive({ value: !action.value }) )
      )
    )
  
  projectFiltered$ = createEffect(() =>
  this.actions$
    .pipe(
      ofType(APP_ACTIONS.projectFiltered),
      tap( (action) => { 
        this.samApp.saveStore("TQprojectFilterId", action.id)
        this.samApp.saveStore("TQprojectFilterCode", action.code)
        this.samApp.saveStore("TQprojectFilterColor", action.color)   
        this.samApp.saveStore("TQprojectFilterList", action.list)   
      })
    ),
    {
      dispatch: false,
    }
  )

}