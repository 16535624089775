<select type="checkbox" 
  class="hour-selector"
  [(ngModel)]="hoursLabel"
  (change)="changeDuration()">
  <option *ngFor="let h of getHourLabels();" value="{{h}}">{{h}}</option>
</select>
<select type="checkbox" 
  class="time-selector"
  [(ngModel)]="minutesLabel"
  (change)="changeDuration()">
  <option *ngFor="let m of getMinuteLabels();" value="{{m}}">{{m}}</option>
</select>