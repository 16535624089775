<div id="todopadPane" class="samPane">
  <div class="samPaneHeader"
    [ngClass]="{'TQ-header-filtered' : todopadFilter}">
    <h4 class="samPaneTitle">
      Todopad
      <a href="https://docs.taskquark.com/todopad.html" target="TQ-docs">
        <fa-icon [icon]="faQuestionCircle" class="TQ-help-icon" title="Help page"></fa-icon>
      </a>
    </h4>
    <div *ngIf="appState.projectFilterCode!= ''">
      <p class="samPaneSubtitle"
        [ngClass]="'TQ-'+appState.projectFilterColor">
        {{appState.projectFilterCode}}
      </p>
      <button 
        class="samPaneSubtitle badge TQ-clear-button mt-3"
        title="Clear (Esc)"
        (click)="clearProjectFilter()">X
      </button>
    </div>
    <fa-icon *ngIf="tasksState.status!='loaded' || todopadLoading"
      [icon]="faSpinner" [spin]="true" [pulse]="true" [size]="'lg'"
      class="samPaneTitle">
    </fa-icon>
    <div class="TQ-filter-area">
      <button class="TQ-filter-button"
        (click)="todopadFilter=!todopadFilter; clearTodopadFilter();">
        <fa-icon [icon]="faFilter" class="fa-lg" title="Filter Todopad (Ctrl-F)"></fa-icon>
      </button>
      <input id="TodopadFilter" type="text" class="pl-1"
        [hidden]="!todopadFilter"
        [ngModel]="this.todopadFilterRegEx" (ngModelChange)="updateTodopadFilter($event)">
      <button class="badge TQ-clear-button ml-1 mr-2"        
        title="Clear (Esc)"
        [hidden]="!todopadFilter"
        (click)="clearTodopadFilter()">X</button>
    </div>
  </div>

  <div>
    <button *ngIf="taskId > 0"
        class="btn btn-sm btn-info TQ-scroll-top-button"
        (click)="clearToTop()">
        <fa-icon [icon]="faArrowUp" title="Scroll to top"></fa-icon>
    </button>
    <button *ngIf="canAddTask"
        class="btn btn-sm btn-success TQ-add-button"
        (click)="editTask()">
        <fa-icon [icon]="faPlus" title="Add Task"></fa-icon>
    </button>

    <div *ngIf="!canAddTask"
      class="alert alert-danger" role="alert">
      You have reached the maximum number of tasks for your subscription plan.
    </div>

    <!-- OPTIONS BAR -->

    <div class="ml-2 pb-2">
      <button
        class="btn btn-sm btn-success date-arrow"
        title="Previous Day"
        (click)="goPreviousDay()">
        <fa-icon [icon]="faCaretLeft" ></fa-icon>
      </button>
      <button
        class="btn m-0 p-0 date-button"
        [class.date-button-today]="(todopadDateString==tqDT.tqDateString)"
        title="Go to Today"
        (click)="goToday()">
        <b>{{this.todopadDayName}} {{todopadDateString}}</b>
      </button>
      <button 
        class="btn btn-sm btn-success date-arrow"
        title="Next Day"
        (click)="goNextDay()">
        <fa-icon [icon]="faCaretRight" ></fa-icon>
      </button>

      <!-- <div style="float:right">
        <button
          class="badge TQ-action-button mb-1 mr-2"
          (click)="toggleAllTasks()">
          <span *ngIf="this.showAllTasks">Hide terminated</span>
          <span *ngIf="!this.showAllTasks">Show terminated</span>
        </button>
      </div> -->
    </div>

    <div>

    <!-- TASKS AGENDA -->

    <table id="Col1" 
      class="TQ-table table table-striped"
      [ngClass]="{
        'tableQuarter' : !this.samApp.onMobile,
        'tableStacked' : this.samApp.onMobile
      }">
      <thead>
        <tr class="table-header-today">
          <th class="col-xs-1 col-1 p-1 text-center">
            Agenda {{agendaTitle}}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr id="col1-{{t.id}}" 
          *ngFor="let t of TQTasksAgenda; let i = index"
          class="m-0 p-0">
          <div #taskCell>
            <td 
              class="TQ-td task-cursor"
              [class.table-success]="(t.id==this.taskId)"
              (click)="editTask(t)">
              <span *ngIf="t.exeTime" 
                class="badge pl-0 TQ-time"
                [ngClass]="{
                  'TQ-time-past'   : (this.tqDT.isTimeBefore(t.exeClockTime, tqDT.tqTimeString)),
                  'TQ-time-current': (t.exeClockTime == tqDT.tqTimeString)
                }">
                {{t.exeClockTime}}
              </span>
              <tq-task-card
                [t]="t" 
                [onDate]="todopadDateString"
                [showProjectCode]="showProjectCode(t.project_code)"
                [showDuration]="false"
              ></tq-task-card>
            </td>
          </div>  
          <tq-task-menu *ngIf="!this.samApp.onMobile"
            [target]="taskCell"
            [canAddTask]="canAddTask"
            (command)="onTaskMenuCommand($event, t)"
          ></tq-task-menu>
        </tr>
      </tbody>
    </table>

    <!-- TODO TASKS -->

    <table 
      class="TQ-table table table-striped"
      [ngClass]="{
        'tableQuarter' : !this.samApp.onMobile,
        'tableStacked' : this.samApp.onMobile
      }">
      <thead>
      <tr class="table-header-today">
        <th class="col-xs-1 col-1 p-1 text-center">
          To-Do List {{todoTitle}}
        </th>
      </tr>
      </thead>
      <tbody>
        <tr id="col2-{{t.id}}" 
          class="m-0 p-0"
          *ngFor="let t of TQTasksTodo; let i = index"
          [class.table-success]="(t.id==this.taskId)">
          <div #taskCell
            [class.table-success]="(t.id==this.taskId)"
            (click)="editTask(t)">
            <td class="TQ-td task-cursor">
              <tq-task-card
                [t]="t" 
                [onDate]="todopadDateString"
                [showProjectCode]="showProjectCode(t.project_code)"
              ></tq-task-card>
            </td>
          </div>  
          <tq-task-menu *ngIf="!this.samApp.onMobile"
            [target]="taskCell"
            [canAddTask]="canAddTask"
            (command)="onTaskMenuCommand($event, t)"
          ></tq-task-menu>
        </tr>
      </tbody>
    </table>

    <!-- WAIT TASKS -->

    <table 
      class="TQ-table table table-striped"
      [ngClass]="{
        'tableQuarter' : !this.samApp.onMobile,
        'tableStacked' : this.samApp.onMobile
      }">
      <thead>
      <tr>
        <th class="col-xs-1 col-1 p-1 text-center">
          On hold
        </th>
      </tr>
      </thead>
      <tbody>
        <tr id="col3-{{t.id}}" 
          class="m-0 p-0"
          *ngFor="let t of TQTasksOnHold; let i = index"
          [class.table-success]="(t.id==this.taskId)">
          <div #taskCell
            [class.table-success]="(t.id==this.taskId)"
            (click)="editTask(t)">
            <td class="TQ-td task-cursor">
              <tq-task-card
                [t]="t" 
                [onDate]="todopadDateString"
                [showProjectCode]="showProjectCode(t.project_code)"
              ></tq-task-card>
            </td>
          </div>  
          <tq-task-menu *ngIf="!this.samApp.onMobile"
            [target]="taskCell"
            [canAddTask]="canAddTask"
            (command)="onTaskMenuCommand($event, t)"
          ></tq-task-menu>
        </tr>
      </tbody>
    </table>

    <!-- OVERDUE TASKS -->

    <table id="Col0" 
      class="TQ-table table table-striped"
      [ngClass]="{
        'tableQuarter' : !this.samApp.onMobile,
        'tableStacked' : this.samApp.onMobile
      }">
      <thead>
        <tr>
          <th class="col-xs-1 col-1 p-1 text-center">
            Past date
          </th>
        </tr>
      </thead>
      <tbody>
        <tr id="col4-{{t.id}}" 
          *ngFor="let t of TQTasksPastDate; let i = index"
          class="m-0 p-0"
          [class.table-success]="(t.id==this.taskId)">
          <div #taskCell
            [class.table-success]="(t.id==this.taskId)"
            (click)="editTask(t)">
            <td class="TQ-td task-cursor">
              <tq-task-card
                [t]="t" 
                [onDate]="todopadDateString"
                [showProjectCode]="showProjectCode(t.project_code)"
              ></tq-task-card>
            </td>
          </div>  
          <tq-task-menu *ngIf="!this.samApp.onMobile"
            [target]="taskCell"
            [canAddTask]="canAddTask"
            (command)="onTaskMenuCommand($event, t)"
          ></tq-task-menu>
        </tr>
      </tbody>
    </table>

    </div>

  </div>
</div>
