import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

import { Store } from '@ngrx/store';
import { AppState } from 'src/app/appState/app.state';
import { appState } from 'src/app/appState/app.selectors';

@Component({
  selector: 'tq-role-selector',
  templateUrl: './tq-role-selector.component.html',
  styleUrl: './tq-role-selector.component.scss'
})
export class TQRoleSelectorComponent 
{
  @Input() roleId: number;
  @Output() roleIdChange = new EventEmitter<number>();

  appState: AppState; 
  appStateSubs: any;
  
  roleItem: any;
  roles: any[];
  roleALL = {id: null, code: '<ALL>'};

  constructor
  (
    private changeDetector: ChangeDetectorRef,
    private store: Store,
  ) 
  {
    this.appStateSubs = this.store.select(appState)
    .subscribe( state => {
      this.appState = state 
    })
  }

  ngOnInit() 
  {
    this.updateSelectedRole();
  } 

  // Parent component changes 
  ngOnChanges() 
  {
    this.updateSelectedRole();
  }

  ngOnDestroy() 
  {
    this.appStateSubs.unsubscribe();
  }

  async getRoles()
  {
    if (this.appState.status != 'loaded') return;

    this.roles = [];

    // Add All role
    this.roles.push(this.roleALL);

    // Get the roles from TQsession
    let roles = this.appState.TQroles;
    if (roles.length == 0) return;
    
    for (var i = 0; i < roles.length; i++)
    {
      let role = roles[i];
      this.roles.push({id: role.id, code: role.code});      
    };
  }


  updateSelectedRole()
  {
    this.getRoles();
    if (this.roleId == null) 
    {
      this.roleItem = this.roleALL;
    }
    else  
    {
      this.roleItem = this.roles.find(x => x.id == this.roleId);
    }
  }

  onDropdownChange(event: any)
  {    
    this.roleId = event.value.id;
    this.updateSelectedRole();

    this.roleIdChange.emit(this.roleId);

    this.changeDetector.detectChanges()
  }

}
