import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { EditorModule } from 'primeng/editor';
import { TreeSelectModule } from 'primeng/treeselect';
import { TreeNode } from 'primeng/api';
import { ConfirmationService, ConfirmEventType } from 'primeng/api';

import { Store } from '@ngrx/store';
import { AppState } from 'src/app/appState/app.state';
import { appState } from 'src/app/appState/app.selectors';
import { ProjectsState } from 'src/app/projects/store/projects.state';
import { projectsState } from 'src/app/projects/store/projects.selectors';
import { NotesState } from 'src/app/notes/store/notes.state';
import { notesState } from 'src/app/notes/store/notes.selectors';
import  * as NOTES_ACTIONS from 'src/app/notes/store/notes.actions';

import { SamuraiService } from 'src/services/samurai/samurai.service';
import { TQApiService } from 'src/app/services/tqapi.service';
import { TQSessionService } from 'src/app/services/tqsession.service';

import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss']
})
export class NoteComponent implements OnInit, OnDestroy
{
  faQuestionCircle = faQuestionCircle;

  appState: AppState;
  appStateSubs: any;

  projectsState: ProjectsState;
  projectsStateSubs: any;
  
  notesState: NotesState;
  notesStateSubs: any;
  canAddNote: boolean = true;

  projectsNodes: TreeNode[];
  selectedProject: any;

  noteId: number;
  noteProject_id: number;
  noteTitle: string = "";
  noteDescription: string = "";

  // A button in the form has been clicked, 
  // thus. disable buttons to avoid repetition
  clicked = false;

  constructor(
    private confirmationService: ConfirmationService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private store: Store, 
    public  samApp: SamuraiService,
    private tqApi: TQApiService,
    private tqSession: TQSessionService,
  )
  {}

  async ngOnInit()
  {
    this.appStateSubs = this.store.select(appState)
      .subscribe( state => {
        this.appState = state 
        if (state.status != 'loaded') return;

        this.canAddNote = this.tqSession.canAddNote()
      })

    this.projectsStateSubs = this.store.select(projectsState)
      .subscribe( state => {
        this.projectsState = state 
        if (state.status != 'loaded') return;

        this.canAddNote = this.tqSession.canAddNote()

        this.projectsNodes = [...this.buildProjectSelector()]
        this.selectedProject=this.findProjectNode(this.projectsNodes, this.noteProject_id)
      })

    this.notesStateSubs = this.store.select(notesState)
      .subscribe( state => {
        this.notesState = state 
        if (state.status != 'loaded') return;

        this.canAddNote = this.tqSession.canAddNote()
      })

    this.noteId = Number.parseInt(this.activeRoute.snapshot.paramMap.get('id'))
    if ( this.noteId == 0 )
    {
      this.noteProject_id = this.appState.projectFilterId;
      if (this.noteProject_id == 0)
      {
        let TQProfile: any;

        // TQProfile = await this.tqSession.getSession()
        // this.noteProject_id = TQProfile['default_project_id']
        this.noteProject_id = this.appState.default_project_id
      }
      this.selectedProject=this.findProjectNode(this.projectsNodes, this.noteProject_id)
    }
    else
    {
      this.getNote(this.noteId)
    }

    // Focus on the first field at top
    // but not onMobile
    if (!this.samApp.onMobile) 
    {
      let elem = document.getElementById("NoteTitle");
      setTimeout(() =>
        {
          elem.focus();
          elem.scrollIntoView({behavior: 'smooth', inline: 'center', block: 'center'});
        }, 100);
    }
  }

  ngOnDestroy(): void {
    this.appStateSubs.unsubscribe()
    this.projectsStateSubs.unsubscribe()
    this.notesStateSubs.unsubscribe()
  }

  @HostListener('document:keydown.control.s', ['$event'])
  CtrlS(event: any)
  {
    event.preventDefault();
    document.getElementById("NoteSaveButton").click()
  }
  @HostListener('document:keydown.control.d', ['$event'])
  CtrlD(event: any)
  {
    event.preventDefault();
    document.getElementById("NoteDuplicateButton").click()
  }
  @HostListener('document:keydown.escape', ['$event'])
  Escape(event: any)
  {
    event.preventDefault();
    //document.getElementById("NoteCancelButton").click()
  }

  async getNote(id: number)
  {
    try
    {
      var res = await this.tqApi.getNote(id)
    }
    catch (apiError)
    {
      this.router.navigate(["/loading"])
    }

    if (res["project_id"] == null)
    {
      this.noteProject_id = 0
    }
    else
    {
      this.noteProject_id = res["project_id"]
      if (this.noteProject_id == null)
      {
        this.noteProject_id = 0
      }
    }
    this.selectedProject=this.findProjectNode(this.projectsNodes, this.noteProject_id)

    this.noteTitle = res["title"]

    // Temporary replace CR with <br> for old descriptions saved from textarea controls
    this.noteDescription = res["description"].replace(/(?:\r\n|\r|\n)/g, '<br>');
  }


  async addNote()
  {
    // Ignore empty text notes
    if (this.noteTitle == "")
    {
      this.clicked = false;
      return;
    }

    var TQNote = {
        "id"          : this.noteId,
        "profile_id"  : this.appState.TQprofileId,
        "project_id"  : this.selectedProject.data,
        "title"       : this.noteTitle,
        "description" : this.noteDescription || ""
      }

    let res = await this.tqApi.postNote(TQNote)

    // Prepare Notepad status for a new note
    this.store.dispatch(NOTES_ACTIONS.selectNote({id: res['id']}))

    this.router.navigate([this.appState.lastPad])
  }

  async duplicateNote()
  {
    // Create a new note
    var TQNote = {
        "id"          : 0,
        "profile_id"  : this.appState.TQprofileId,
        "project_id" : this.selectedProject.data,
        "title"       : (this.noteTitle + " (duplicated)").slice(0,254),
        "description" : this.noteDescription || ""
      }

    let res = await this.tqApi.postNote(TQNote)

    // Prepare Notepad status for a new note
    this.store.dispatch(NOTES_ACTIONS.selectNote({id: res['id']}))

    this.router.navigate([this.appState.lastPad])
  }

  cancelNote()
  {
    this.router.navigate([this.appState.lastPad])
  }

  async deleteNote()
  {
    // this.store.dispatch(NOTES_ACTIONS.deleteNote({id: this.noteId}))
    await this.tqApi.deleteNote(this.noteId)

    this.store.dispatch(NOTES_ACTIONS.selectNote({id: 0}))

    this.router.navigate([this.appState.lastPad])
  }

  buildProjectSelector(): TreeNode[]
  {
    function getNodes(projectList, levelProjects: Array<any>, level: number, ignoreProject: number)
    {
      const nodes = [];
      levelProjects.forEach( p => 
      {
        if (p.id == ignoreProject) return;

        let children = projectList.filter(ch => ch.parent_project_id == p.id)
        nodes.push(
          {
            data: p.id,
            label: p.code,
            children: (children.length != 0) 
                        ? getNodes(projectList, 
                                   children, 
                                   level+1, 
                                   ignoreProject) 
                        : [],
            //expandedIcon: "pi pi-folder",
            //collapsedIcon: "pi pi-folder",
            //selectable: p.id != ignoreProject,
          }
        )
      })
      return nodes
    }

    let tree:TreeNode[] = []
    let projectList = [...this.projectsState.TQprojects] 
    
    // Start with root projects as level 0
    tree = getNodes(projectList, projectList.filter(p => p.parent_project_id == null), 0, 0)

    return tree
  }
  
  findProjectNode(tree:TreeNode[], id:number): TreeNode
  {
    for (let i in tree)
    {
      let p = tree[i]
      if (p.data == id) return p       
      if (p.children.length != 0 ) {
        let node = this.findProjectNode(p.children, id)
        if (node) return node
      }
    }
  }

  confirmDelete() 
  {
    this.confirmationService.confirm({
      accept: async () => { 
        await this.deleteNote()
      },
      reject: (type:ConfirmEventType) => {
        switch(type) {
          case ConfirmEventType.REJECT:
            this.clicked = false
          break;
          case ConfirmEventType.CANCEL:
            this.clicked = false
          break;
        }
      }
    });
  }

}
