<div id="workpadPane" class="samPane"
  cdkDropListGroup>
  <div class="samPaneHeader"
    [ngClass]="{'TQ-header-filtered' : workpadFilter}">
    <h4 class="samPaneTitle">
      Workpad
      <a href="https://docs.taskquark.com/workpad.html" target="TQ-docs">
        <fa-icon [icon]="faQuestionCircle" class="TQ-help-icon" title="Help page"></fa-icon>
      </a>
    </h4>
    <div *ngIf="appState.projectFilterCode!= ''">
      <p class="samPaneSubtitle"
        [ngClass]="'TQ-'+appState.projectFilterColor">
        {{appState.projectFilterCode}}
      </p>
      <button 
        class="samPaneSubtitle badge TQ-clear-button mt-3"
        title="Clear (Esc)"
        (click)="clearProjectFilter()">X
      </button>
    </div>
    <fa-icon *ngIf="tasksState.status!='loaded' || workpadLoading"
      [icon]="faSpinner" [spin]="true" [pulse]="true" [size]="'lg'"
      class="samPaneTitle">
    </fa-icon>
    <div class="TQ-filter-area">
      <button class="TQ-filter-button"
        (click)="workpadFilter=!workpadFilter; clearWorkpadFilter();">
        <fa-icon [icon]="faFilter" class="fa-lg" title="Filter Workpad (Ctrl-F)"></fa-icon>
      </button>
      <input id="WorkpadFilter" type="text" 
        class="pl-1"
        [hidden]="!workpadFilter"
        [ngModel]="this.workpadFilterRegEx" 
        (ngModelChange)="updateWorkpadFilter($event)">
      <button class="badge TQ-clear-button ml-1 mr-2"
        title="Clear (Esc)"
        [hidden]="!workpadFilter"
        (click)="clearWorkpadFilter()">X</button>
    </div>
  </div>

  <div>
    <button *ngIf="taskId > 0"
        class="btn btn-sm btn-info TQ-scroll-top-button"
        (click)="clearToTop()">
        <fa-icon [icon]="faArrowUp" title="Scroll to top"></fa-icon>
    </button>
    <button *ngIf="canAddTask"
        class="btn btn-sm btn-success TQ-add-button"
        (click)="editTask()">
        <fa-icon [icon]="faPlus" title="Add Task"></fa-icon>
    </button>

    <div *ngIf="!canAddTask"
      class="alert alert-danger" role="alert">
      You have reached the maximum number of tasks for your subscription plan.
    </div>


    <!-- COLUMN 1 -->

    <table id="Col1" *ngIf="prefWorkpadNumCols>=1"
          [ngClass]="{
            'tableStacked' : this.samApp.onMobile,
            'tableEqual'   :!this.samApp.onMobile
          }"
          [ngStyle]="this.samApp.onMobile ? {'width':'100%'} : { width:getColumnWidth() }"
          cdkDropList #Col1List="cdkDropList"
          [cdkDropListData]="Col1Status.value"
          (cdkDropListDropped)="drop($event)"
          class="drag-origin" 
          class="TQ-table table table-striped"> 
      <thead>
        <tr>
          <th class="col-xs-1 col-1 p-1 text-center">
            <select #Col1Status
              type="checkbox" class="TQ-checkbox"
              [ngModel]="taskCol1title" (ngModelChange)="updateTasksCol1($event)">
              <option value="any">all</option>
              <option value="planning">planning</option>
              <option value="todo">to do</option>
              <option value="doing">doing</option>
              <option value="waiting">waiting</option>
              <option value="stopped">stopped</option>
              <option value="done">done</option>
              <option value="canceled">canceled</option>
            </select>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr id="col1-{{t.id}}" 
          *ngFor="let t of TQTasksCol1; let i = index"
          class="m-0 p-0 drag-destination" 
          [class.table-success]="(t.id==this.taskId)" 
          cdkDrag 
          [cdkDragDisabled]="this.samApp.onMobile" 
          [cdkDragData]="t.id">
          <div #taskCell 
            [class.table-success]="(t.id==this.taskId)"
            (click)="editTask(t)">
            <td class="TQ-td task-cursor">
              <tq-task-card 
                [t]="t"
                [onDate]="tqDT.tqDateString"
                [showProjectCode]="showProjectCode(t.project_code)"
              ></tq-task-card>
            </td>
          </div>
          <tq-task-menu *ngIf="!this.samApp.onMobile"
            [target]="taskCell"
            [canAddTask]="canAddTask"
            (command)="onTaskMenuCommand($event, t)"
          ></tq-task-menu>
        </tr>
      </tbody>
    </table>


    <!-- COLUMN 2-->

    <table id="Col2" *ngIf="prefWorkpadNumCols>=2"
          [ngClass]="{
            'tableStacked' : this.samApp.onMobile,
            'tableEqual'   :!this.samApp.onMobile
          }"
          [ngStyle]="this.samApp.onMobile ? {} : { width :getColumnWidth() }"
          cdkDropList #Col2List="cdkDropList"
          [cdkDropListData]="Col2Status.value"
          (cdkDropListDropped)="drop($event)"
          class="drag-origin"
          class="TQ-table table table-striped"> 
      <thead>
        <tr>
          <th class="col-xs-1 col-1 p-1 text-center">
            <select #Col2Status
              type="checkbox" class="TQ-checkbox"
              [ngModel]="taskCol2title" (ngModelChange)="updateTasksCol2($event)">
              <option value="any">all</option>
              <option value="planning">planning</option>
              <option value="todo">to do</option>
              <option value="doing">doing</option>
              <option value="waiting">waiting</option>
              <option value="stopped">stopped</option>
              <option value="done">done</option>
              <option value="canceled">canceled</option>
            </select>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr id="col2-{{t.id}}" 
          *ngFor="let t of TQTasksCol2; let i = index"
          class="m-0 p-0 drag-destination"
          [class.table-success]="(t.id==this.taskId)" 
          cdkDrag 
          [cdkDragDisabled]="this.samApp.onMobile" 
          [cdkDragData]="t.id">
          <div #taskCell
            [class.table-success]="(t.id==this.taskId)"
            (click)="editTask(t)">
            <td class="TQ-td task-cursor">
              <tq-task-card 
                [t]="t"
                [onDate]="tqDT.tqDateString"
                [showProjectCode]="showProjectCode(t.project_code)"
              ></tq-task-card>
            </td>
          </div>
          <tq-task-menu *ngIf="!this.samApp.onMobile"
            [target]="taskCell"
            [canAddTask]="canAddTask"
            (command)="onTaskMenuCommand($event, t)"
          ></tq-task-menu>
        </tr>
      </tbody>
    </table>


    <!-- COLUMN 3-->

    <table id="Col3" *ngIf="prefWorkpadNumCols>=3"
          [ngClass]="{
            'tableStacked' : this.samApp.onMobile,
            'tableEqual'   :!this.samApp.onMobile
          }"
          [ngStyle]="this.samApp.onMobile ? {} : { width :getColumnWidth() }"
          cdkDropList #Col3List="cdkDropList"
          [cdkDropListData]="Col3Status.value"
          (cdkDropListDropped)="drop($event)"
          class="drag-origin"
          class="TQ-table table table-striped"> 
      <thead>
        <tr>
          <th class="col-xs-1 col-1 p-1 text-center">
            <select #Col3Status
              type="checkbox" class="TQ-checkbox"
              [ngModel]="taskCol3title" (ngModelChange)="updateTasksCol3($event)">
              <option value="any">all</option>
              <option value="planning">planning</option>
              <option value="todo">to do</option>
              <option value="doing">doing</option>
              <option value="waiting">waiting</option>
              <option value="stopped">stopped</option>
              <option value="done">done</option>
              <option value="canceled">canceled</option>
            </select>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr id="col3-{{t.id}}"
          *ngFor="let t of TQTasksCol3; let i = index"
          class="m-0 p-0 drag-destination"
          [class.table-success]="(t.id==this.taskId)" 
          cdkDrag 
          [cdkDragDisabled]="this.samApp.onMobile" 
          [cdkDragData]="t.id">
          <div #taskCell
            [class.table-success]="(t.id==this.taskId)"
            (click)="editTask(t)">
            <td class="TQ-td task-cursor">
              <tq-task-card 
                [t]="t"
                [onDate]="tqDT.tqDateString"
                [showProjectCode]="showProjectCode(t.project_code)"
              ></tq-task-card>
            </td>
          </div>
          <tq-task-menu *ngIf="!this.samApp.onMobile"
            [target]="taskCell"
            [canAddTask]="canAddTask"
            (command)="onTaskMenuCommand($event, t)"
          ></tq-task-menu>
        </tr>
      </tbody>
    </table>


    <!-- COLUMN 4-->

    <table id="col4" *ngIf="prefWorkpadNumCols>=4"
          [ngClass]="{
            'tableStacked' : this.samApp.onMobile,
            'tableEqual'   :!this.samApp.onMobile
          }"
          [ngStyle]="this.samApp.onMobile ? {} : { width :getColumnWidth() }"
          cdkDropList #Col4List="cdkDropList"
          [cdkDropListData]="Col4Status.value"
          (cdkDropListDropped)="drop($event)"
          class="drag-origin"
          class="TQ-table table table-striped"> 
      <thead>
        <tr>
          <th class="col-xs-1 col-1 p-1 text-center">
            <select #Col4Status
              type="checkbox" class="TQ-checkbox"
              [ngModel]="taskCol4title" (ngModelChange)="updateTasksCol4($event)">
              <option value="any">all</option>
              <option value="planning">planning</option>
              <option value="todo">to do</option>
              <option value="doing">doing</option>
              <option value="waiting">waiting</option>
              <option value="stopped">stopped</option>
              <option value="done">done</option>
              <option value="canceled">canceled</option>
            </select>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr id="col4-{{t.id}}" 
          *ngFor="let t of TQTasksCol4; let i = index"
          class="m-0 p-0 drag-destination"
          [class.table-success]="(t.id==this.taskId)" 
          cdkDrag 
          [cdkDragDisabled]="this.samApp.onMobile" 
          [cdkDragData]="t.id">
          <div #taskCell
            [class.table-success]="(t.id==this.taskId)"
            (click)="editTask(t)">
            <td class="TQ-td task-cursor">
              <tq-task-card 
                [t]="t"
                [onDate]="tqDT.tqDateString"
                [showProjectCode]="showProjectCode(t.project_code)"
              ></tq-task-card>
            </td>
          </div>
          <tq-task-menu *ngIf="!this.samApp.onMobile"
            [target]="taskCell"
            [canAddTask]="canAddTask"
            (command)="onTaskMenuCommand($event, t)"
          ></tq-task-menu>
        </tr>
      </tbody>
    </table>


    <!-- COLUMN 5-->

    <table id="col5" *ngIf="prefWorkpadNumCols>=5"
      [ngClass]="{
        'tableStacked' : this.samApp.onMobile,
        'tableEqual'   :!this.samApp.onMobile
      }"
      [ngStyle]="this.samApp.onMobile ? {} : { width :getColumnWidth() }"
      cdkDropList #Col5List="cdkDropList"
      [cdkDropListData]="Col5Status.value"
      (cdkDropListDropped)="drop($event)"
      class="drag-origin"
      class="TQ-table table table-striped"> 
      <thead>
        <tr>
          <th class="col-xs-1 col-1 p-1 text-center">
            <select #Col5Status
              type="checkbox" class="TQ-checkbox"
              [ngModel]="taskCol5title" (ngModelChange)="updateTasksCol5($event)">
              <option value="any">all</option>
              <option value="planning">planning</option>
              <option value="todo">to do</option>
              <option value="doing">doing</option>
              <option value="waiting">waiting</option>
              <option value="stopped">stopped</option>
              <option value="done">done</option>
              <option value="canceled">canceled</option>
            </select>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr id="col5-{{t.id}}"
          *ngFor="let t of TQTasksCol5; let i = index"
          class="m-0 p-0 drag-destination"
          [class.table-success]="(t.id==this.taskId)" 
          cdkDrag 
          [cdkDragDisabled]="this.samApp.onMobile" 
          [cdkDragData]="t.id">
          <div #taskCell
            class="task-cursor"
            [class.table-success]="(t.id==this.taskId)"
            (click)="editTask(t)">
            <td class="TQ-td task-cursor">
              <tq-task-card
                [t]="t"
                [onDate]="tqDT.tqDateString"
                [showProjectCode]="showProjectCode(t.project_code)"
              ></tq-task-card>
            </td>
          </div>
          <tq-task-menu *ngIf="!this.samApp.onMobile"
            [target]="taskCell"
            [canAddTask]="canAddTask"
            (command)="onTaskMenuCommand($event, t)"
          ></tq-task-menu>
        </tr>
      </tbody>
    </table>

  </div>
</div>
