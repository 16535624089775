import { Component, Injectable } from '@angular/core';

import { SamuraiService } from 'src/services/samurai/samurai.service';
import { TQApiService } from 'src/app/services/tqapi.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent 
{
  constructor
  (
    public samApp: SamuraiService,
    public tqAPI: TQApiService,
  )
  {}

}
