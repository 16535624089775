import { NgModule } from '@angular/core';

import { SharedModule } from 'src/app/shared/shared.module';

import { MonthpadComponent } from './monthpad/monthpad.component';
import { TaskComponent } from './task/task.component';
import { TodopadComponent } from './todopad/todopad.component';
import { WeekpadComponent } from './weekpad/weekpad.component';
import { WorkpadComponent } from './workpad/workpad.component';


@NgModule({
  declarations: [
    MonthpadComponent,
    TaskComponent,
    TodopadComponent,
    WeekpadComponent,
    WorkpadComponent,
  ],
  imports: [
    SharedModule,
  ],
})
export class TasksModule { }
