<span *ngIf="timezoneSelector=='global' && (allowProfile || allowFloating)"
  (click)="cycleTimezone()">
  <fa-icon [icon]="faGlobe" class="timezone-logo" title="Timezone"></fa-icon>
</span>
<span *ngIf="timezoneSelector=='profile'"
  (click)="cycleTimezone()">
  <fa-icon [icon]="faUser" class="timezone-logo" title="Profile Timezone"></fa-icon>
</span>
<span *ngIf="timezoneSelector=='floating'"
  (click)="cycleTimezone()">
  <fa-icon [icon]="faClock" class="timezone-logo" title="Floating Timezone"></fa-icon>
</span>
<span *ngIf="timezoneSelector=='global'" class="ml-1">
  <select type="checkbox" 
    class="timezone-selector"
    [(ngModel)]="zone1Label"
    (change)="changeZone1()">
    <option *ngFor="let z1 of TZzone1Labels;" value="{{z1}}">{{z1}}</option>
  </select>
  <select type="checkbox" 
    *ngIf="zone2Label"
    class="timezone-selector"
    [(ngModel)]="zone2Label"
    (change)="changeZone2()">
    <option *ngFor="let z2 of TZzone2Labels;" value="{{z2}}">{{z2}}</option>
  </select>
  <select type="checkbox" 
    *ngIf="zone3Label"
    class="timezone-selector"
    [(ngModel)]="zone3Label"
    (change)="changeZone3()">
    <option *ngFor="let z3 of TZzone3Labels;" value="{{z3}}">{{z3}}</option>
  </select>
</span>
