import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from "@angular/router";

import { Store } from '@ngrx/store';
import { AppState } from 'src/app/appState/app.state';
import * as APP_ACTIONS from 'src/app/appState/app.actions';
import { appState } from 'src/app/appState/app.selectors';
import { ProjectsState } from 'src/app/projects/store/projects.state';
import * as PROJECTS_ACTIONS from 'src/app/projects/store/projects.actions';
import { projectsState } from 'src/app/projects/store/projects.selectors';
import * as TASKS_ACTIONS from 'src/app/tasks/store/tasks.actions';

import { SamuraiService } from 'src/services/samurai/samurai.service';
import { TQApiService } from 'src/app/services/tqapi.service';
import { TQSessionService } from 'src/app/services/tqsession.service';

import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';


declare var google: any;

@Component({
  selector: 'app-project-importer',
  templateUrl: './project-importer.component.html',
  styleUrl: './project-importer.component.scss'
})
export class ProjectImporterComponent implements OnInit, OnDestroy
{
  faQuestionCircle = faQuestionCircle;

  appState: AppState;
  appStateSubs: any;

  projectsState: ProjectsState;
  projectsStateSubs: any;
  canAddProject: boolean = false;

  googleCalendarApiClient = null;
  googleCalendarAccessRole = null;
  googleCalendarAccessToken = null;
  googleCalendarRefreshToken = null;
  googleCalendarAccount = null;
  googleCalendarsList = null;
  googleCalendarSync = true;
  googleCalendarId = null;
  googleCalendarSummary = null;


  constructor(
    private router: Router,
    private store: Store,
    private tqApi: TQApiService,
    private tqSession: TQSessionService,
    private samApp: SamuraiService,
  )
  {}

  async ngOnInit()
  {
    //this.store.dispatch(APP_ACTIONS.enterTQpad( {pad:'projectpad'} ))

    this.appStateSubs = this.store.select(appState)
      .subscribe( state => {
        this.appState = state 
        
        this.canAddProject = this.tqSession.canAddProject();
      })

    this.projectsStateSubs = this.store.select(projectsState)
      .subscribe( state => {
        this.projectsState = state 

        this.canAddProject = this.tqSession.canAddProject();

      })
    }
    ngOnDestroy()
    {
      this.appStateSubs.unsubscribe();
      this.projectsStateSubs.unsubscribe();
    }
  
    
    async connectGoogleCalendar()
    {
      // https://www.googleapis.com/auth/userinfo.email	See your primary Google Account email address
      // https://www.googleapis.com/auth/userinfo.profile	See your personal info, including any personal info you've made publicly available openid associate you with your personal info on Google
  
      // https://www.googleapis.com/auth/calendar	See, edit, share, and permanently delete all the calendars you can access using Google Calendar.
      // https://www.googleapis.com/auth/calendar.readonly	See and download any calendar you can access using your Calendar.
      // https://www.googleapis.com/auth/calendar.events	View and edit events on all your calendars.
      // https://www.googleapis.com/auth/calendar.events.readonly	View events on all your calendars.
      // https://www.googleapis.com/auth/calendar.settings.readonly	View your Calendar settings.
      // https://www.googleapis.com/auth/calendar.addons.execute
  
      // https://www.googleapis.com/auth/calendar.app.created,
  
      // Prompt the user to authorize the client
//      this.googleCalendarApiClient = google.accounts.oauth2.initTokenClient({
        this.googleCalendarApiClient = google.accounts.oauth2.initCodeClient({
        client_id: "1069477341287-df0ooghjc5uv5nihavvjv2mc1vqts2tc.apps.googleusercontent.com",
        scope: "https://www.googleapis.com/auth/calendar.readonly \
                https://www.googleapis.com/auth/calendar.events ",
//        ux_mode: "popup", 
//        prompt: 'force',
        access_type: 'offline',
        callback: async (response) => {
          let res = await this.tqApi.getProfileGoogleCalendars(response.code)
          this.googleCalendarAccessToken = res['access_token']
          this.googleCalendarRefreshToken = res['refresh_token']
          this.googleCalendarsList = res['calendars']
        }
      });
  
      // Request a token to force the user to select the account
//      this.googleCalendarApiClient.requestAccessToken()  
      this.googleCalendarApiClient.requestCode()  
    }

    async importGoogleCalendar()
    {
        // Getting Google Calendar Events...
        let calendar = {
          profile_id: this.appState.TQprofileId,
          parent_project_id: this.appState.projectFilterId,
          google_calendar_id: this.googleCalendarId,
          google_calendar_sync: this.googleCalendarSync,
          google_access_role: this.googleCalendarsList.find(c => c.id == this.googleCalendarId).accessRole,
          google_access_token: this.googleCalendarAccessToken,
          google_refresh_token: this.googleCalendarRefreshToken,
        }
        let res = await this.tqApi.importProjectGoogleCalendar(calendar);

        // Update sidebar with the new project
        this.store.dispatch(PROJECTS_ACTIONS.loadProjectsList())
        this.store.dispatch(PROJECTS_ACTIONS.selectProject({id: res['project_id']})); 
       
        this.store.dispatch(TASKS_ACTIONS.loadTasksList());

        this.router.navigate(['projectpad'])
    }

    cancelImport()
    {
      this.router.navigate(['projectpad'])
    }
}
