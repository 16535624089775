<mat-datepicker-toggle 
  matSuffix [for]="DatePicker" 
  class="TQ-datepicker"
></mat-datepicker-toggle>
<mat-datepicker #DatePicker 
  disabled="false"
></mat-datepicker>
<input #DateInput
  class="date-picker" 
  [matDatepicker]="DatePicker" 
  [min]="minDate"
  [max]="maxDate"
  [(ngModel)]="date" 
  (dateChange)="changeDate($event)"
  disabled
>
