import { createSelector, createFeatureSelector } from '@ngrx/store';
import { ProjectsState } from './projects.state';


export const getProjectsState = createFeatureSelector('projectsState')

export const projectsState = createSelector(
  getProjectsState,
  (projectsState: ProjectsState) => projectsState
)


