import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatTabGroup } from '@angular/material/tabs';

import { EditorModule } from 'primeng/editor';
import { TableModule } from 'primeng/table';
import { TreeSelectModule } from 'primeng/treeselect';
import { TreeNode } from 'primeng/api';
import { ConfirmationService, ConfirmEventType } from 'primeng/api';

import { Store } from '@ngrx/store';
import { AppState } from 'src/app/appState/app.state';
import { appState } from 'src/app/appState/app.selectors';
import { ProjectsState } from 'src/app/projects/store/projects.state';
import { projectsState } from 'src/app/projects/store/projects.selectors';
import { TasksState } from 'src/app/tasks/store/tasks.state';
import { tasksState } from 'src/app/tasks/store/tasks.selectors';
import  * as TASKS_ACTIONS from 'src/app/tasks/store/tasks.actions';

import { Activity } from 'src/app/models/activity';

import { SamuraiService } from 'src/services/samurai/samurai.service';
import { TQApiService } from 'src/app/services/tqapi.service';
import { TQDateTimeService } from 'src/app/services/tqdatetime.service';
import { TQSessionService } from 'src/app/services/tqsession.service';

import { DateTime } from 'luxon'

import { faFile, faQuestionCircle, faCalendarCheck } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrl: './activity.component.scss'
})
export class ActivityComponent implements OnInit, OnDestroy
{
  faFile = faFile;
  faQuestionCircle = faQuestionCircle;
  faCalendarCheck = faCalendarCheck;

  appState: AppState;
  appStateSubs: any;

  projectsState: ProjectsState;
  projectsStateSubs: any;

  tasksState: TasksState;
  tasksStateSubs: any;
  canAddActivity: boolean = true;
  canEditActivity: boolean = true;

  // Local copy of project
  projectId: number = 0;
  projectCode: string = "";
  projectTitle: string = "";

  // Local copy of task
  taskId: number = 0; 
  taskProject_id: number;
  taskTitle: string = "";
  taskDescription: string = "";

  // Local copy of activity in the form
  activityProjectCode: string = "";
  activityProjectTitle: string = "";
  activityTaskTitle: string = "";
  activityId: number = 0;
  activityStartDate: DateTime;
  activityStartTime: string = "";
  activityEndDate: DateTime;
  activityEndTime: string = "";
  activityDescription: string = "";
  activityResource: string = "";
  activityValue: number = 0;
  activityUnit: string = ""; 
  activityCreatedAt: string = "";
  activityUpdatedAt: string = "";

  activityDuration: string = "";

  activities: any;
  activities_number: number = 0;
  activities_time: number = 0;
  
  selectedTab = 1;

  editing = false;

  constructor(
    private confirmationService: ConfirmationService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private store: Store,
    public  samApp: SamuraiService,
    public  tqSession: TQSessionService,
    private tqApi: TQApiService,
    public  tqDT: TQDateTimeService,
  )
  {}

  async ngOnInit()
  {
    this.appStateSubs = this.store.select(appState)
    .subscribe( state => {
      this.appState = state       
      
      this.canAddActivity = this.tqSession.canAddTask()     
    })

    this.projectsStateSubs = this.store.select(projectsState)
      .subscribe( state => {
        this.projectsState = state 
        
        this.canAddActivity = this.tqSession.canAddTask()     
     })

    this.tasksStateSubs = this.store.select(tasksState)
      .subscribe( state => {
        this.tasksState = state 

        this.canAddActivity = this.tqSession.canAddTask()     
      })

    // Get query parameters
    this.projectId = 0;
    this.taskId = 0;
    this.activeRoute.queryParams.subscribe(params => {
      // Check for task activity
      if (params['project'] != null)
        {
          this.projectId = Number.parseInt(params['project'])
        }
      // Check for task activity
      if (params['task'] != null)
      {
        this.taskId = Number.parseInt(params['task'])
      }
    })

    // Check for the task and project
    if ( this.taskId != 0 )
    {
      await this.getTask(this.taskId)
      this.projectId = this.taskProject_id
    }
    await this.getProject(this.projectId)
    
    // TODO Temporary fix for the taskProject_id, until imports are fully implemented
    this.canEditActivity = true; 

    // Prepare activity header and form
    this.clearActivity()
    
    // Load activities
    await this.getActivities()

    // Focus on the description field, but not onMobile
    if (!this.samApp.onMobile) 
    {
      let elem = document.getElementById("activityDescription");
      setTimeout(() =>
        {
          elem.focus();
          elem.scrollIntoView({behavior: 'instant', inline: 'center', block: 'center'});
        }, 100);
    }
  }

  ngOnDestroy(): void 
  {
    this.appStateSubs.unsubscribe()
    this.projectsStateSubs.unsubscribe()
    this.tasksStateSubs.unsubscribe()
  }

  @HostListener('document:keydown.control.s', ['$event'])
  CtrlS(event: any)
  {
    event.preventDefault();
    document.getElementById("ActivitySaveButton").click()
  }
  @HostListener('document:keydown.control.d', ['$event'])
  CtrlD(event: any)
  {
    event.preventDefault();
    if (this.editing)
    {
      document.getElementById("ActivityDuplicateButton").click()
    }
  }
  @HostListener('document:keydown.escape', ['$event'])
  Escape(event: any)
  {
    event.preventDefault();
    document.getElementById("ActivityCancelButton").click()
  }

  async getTask(id: number)
  {
    // Ignore new tasks
    if (id == 0) return;

    try
    {
      var res = await this.tqApi.getTask(id)
    }
    catch (apiError)
    {
      this.router.navigate(["/loading"])
    }

    this.taskProject_id = res["project_id"]
    this.taskTitle = res["title"]
    this.taskDescription = res["description"]
  }

  async getProject(id: number)
  {
    try
    {
      var res = await this.tqApi.getProject(id)
    }
    catch (apiError)
    {
      this.router.navigate(["/loading"])
    }

    this.projectCode = res["code"]
    this.projectTitle = res["title"]
  }

  async getActivities()
  {
    if (this.taskId == 0) 
    {
      await this.getProjectActivities(this.projectId)
    }
    else
    {
      await this.getTaskActivities(this.taskId)
    }
  }

  async getTaskActivities(taskId: number)
  {
    try
    {
      var res = await this.tqApi.getActivitiesByTaskId(taskId)
    }
    catch (apiError)
    {
      this.router.navigate(["/loading"])
    }

    this.activities = res

    // Get activity summary
    this.activities_number = this.activities.length
    this.activities_time = this.activities.reduce((acc, act) => acc + act.value, 0)
  }

  async getProjectActivities(projectId: number)
  {
    try
    {
      var res = await this.tqApi.getActivitiesByProjectId(projectId)
    }
    catch (apiError)
    {
      this.router.navigate(["/loading"])
    }

    this.activities = res

    // Get activity summary
    this.activities_number = this.activities.length
    this.activities_time = this.activities.reduce((acc, act) => acc + act.value, 0)
  }

  clearActivity()
  {
    this.activityId = 0
    this.activityStartDate = this.tqDT.tqDateTime
    this.activityStartTime = null; this.tqDT.tqTimeString
    this.activityEndDate = null
    this.activityEndTime = null
    this.activityDescription = null
    this.activityResource = null
    this.activityValue = null
    this.activityUnit = null
    this.activityCreatedAt = null
    this.activityUpdatedAt = null
    this.activityDuration = null
  }

  getActivity(id: number)
  {
    // Ignore new tasks
    if (id == 0) return;
    
    // Load the activity
    let act = this.activities.find(a => a.id == id)
    
    this.activityId = id
    this.activityStartDate = this.tqDT.toLuxon(this.tqDT.dateToTZ(act.startDate, act.startTime))
    this.activityStartTime = this.tqDT.timeToTZ(act.startDate, act.startTime)
    this.activityEndDate = this.tqDT.toLuxon(this.tqDT.dateToTZ(act.endDate, act.endTime))
    this.activityEndTime = this.tqDT.timeToTZ(act.endDate, act.endTime)
    this.activityDescription = act.description
    this.activityResource = act.resource
    this.activityValue = act.value
    this.activityUnit = act.unit
    this.activityCreatedAt = act.createdAt
    this.activityUpdatedAt = act.updatedAt

    // Expand the activity 
    switch (this.activityResource)
    {
      case "time":
        this.activityDuration = this.tqDT.minutesToDuration(act.value)
        break;
    }

  }

  editActivity(id: number)
  {
    this.getActivity(id)

    this.editing = true;
  }

  cancelActivity()
  {
    if (this.activityId == 0 && this.editing == false) 
    {
      this.router.navigate([this.appState.lastPad])
    }
    else
    {
      this.editing = false;
      this.clearActivity();  
    }
  }

  async addActivity()
  {
    // Temporary set to time, if Duration is set
    if (this.activityDuration != null)
    {
      this.activityResource = "time"
    }

    switch (this.activityResource)
    {
      case "time":
        this.activityValue = this.tqDT.durationToMinutes(this.activityDuration)
        this.activityUnit = "minutes"
        break;
    }

    let TQActivity = {
      "id"          : this.activityId,
      "profile_id"  : this.appState.TQprofileId,
      "project_id"  : this.projectId,
      "task_id"     : this.taskId,
      "startDate"   : this.tqDT.dateToUTC(this.tqDT.formatDateISO(this.activityStartDate), this.activityStartTime) || '',
      "startTime"   : this.tqDT.timeToUTC(this.tqDT.formatDateISO(this.activityStartDate), this.activityStartTime) || '',
      "endDate"     : this.tqDT.dateToUTC(this.tqDT.formatDateISO(this.activityEndDate), this.activityEndTime) || '',
      "endTime"     : this.tqDT.timeToUTC(this.tqDT.formatDateISO(this.activityEndDate), this.activityEndTime) || '',
      "description" : this.activityDescription || '', 
      "resource"    : this.activityResource || '',
      "value"       : this.activityValue || 0,   
      "unit"        : this.activityUnit || '',
    }
    var res = await this.tqApi.postActivity(TQActivity)

    await this.getActivities()

    this.clearActivity();
  }

  async duplicateActivity()
  {
    if (this.activityId == 0) return

    let TQActivity = {
      "id"          : 0,
      "profile_id"  : this.appState.TQprofileId,
      "project_id"  : this.projectId,
      "task_id"     : this.taskId,
      "startDate"   : this.tqDT.dateToUTC(this.tqDT.formatDateISO(this.activityStartDate), this.activityStartTime) || '',
      "startTime"   : this.tqDT.timeToUTC(this.tqDT.formatDateISO(this.activityStartDate), this.activityStartTime) || '',
      "endDate"     : this.tqDT.dateToUTC(this.tqDT.formatDateISO(this.activityEndDate), this.activityEndTime) || '',
      "endTime"     : this.tqDT.timeToUTC(this.tqDT.formatDateISO(this.activityEndDate), this.activityEndTime) || '',
      "description" : this.activityDescription || '',
      "resource"    : this.activityResource || '',
      "value"       : this.activityValue || 0,   
      "unit"        : this.activityUnit || '',
    }
    var res = await this.tqApi.postActivity(TQActivity)
    
    await this.getActivities()
    
    this.activityId = res['id']
    this.getActivity(this.activityId)
  }

  async deleteActivity(id: number)
  {
    await this.tqApi.deleteActivity(this.activityId)

    await this.getActivities()
    
    this.clearActivity();
  }


  /**
   * Clear and copy functions
   */

  clearActivityStartDate()
  {
    this.activityStartDate = null;
    this.clearActivityStartTime()
  }

  clearActivityStartTime()
  {
    this.activityStartTime = null;
  }

  clearActivityEndDate()
  {
    this.activityEndDate = null;
    this.clearActivityEndTime()
  }

  clearActivityEndTime()
  {
    this.activityEndTime = null;
  }

  clearActivityDuration()
  {
    this.activityResource = null;
    this.activityValue = null;
    this.activityUnit = null;

    this.activityDuration = null;
  }

  confirmDeleteActivity() 
  {
    this.confirmationService.confirm({
      key: 'deleteActivity',
      accept: async () => { 
        await this.deleteActivity(this.activityId)
      },
      reject: (type:ConfirmEventType) => {
        switch(type) {
          case ConfirmEventType.REJECT:
            this.editing = true
          break;
          case ConfirmEventType.CANCEL:
            this.editing = true
          break;
        }
      }
    });
  }

  //--- Activity debugging functions ---//

  dumpActivity() 
  {
    console.log("---");
///    console.log("taskStartWorkDate", this.taskStartWorkDate);
  }
}
