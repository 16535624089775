<nav *ngIf="appState.sidebarActive"
  class="sidebar"
  [ngClass]="appState.sidebarCollapsed?'sidebarCollapsed':'sidebarExpanded'">

  <div>
    <button title="Expand/Collapse Sidebar"
      class="btn btn-primary text-white sidebar-button"
      (click)="toggleSidebar()">
      <fa-icon *ngIf="!appState.sidebarCollapsed" [icon]="faAngleDoubleLeft"></fa-icon>
      <fa-icon *ngIf=" appState.sidebarCollapsed" [icon]="faAngleDoubleRight"></fa-icon>
    </button>
  </div>

  <!-- EXPANDED SIDEBAR MENU -->

  <div *ngIf="!appState.sidebarCollapsed" 
    class="mt-1 pl-2 pr-2">

    <div *ngIf="tqDT.tqDateString"
      class="mb-2 ml-4">
      <p class="ml-3">{{tqDT.tqDayName}} {{tqDT.tqDateString}}</p>
      <p class="ml-4 pl-1">{{tqDT.tqTimeLongString}}</p>
      <p class="ml-4 pl-2 utcOffset">UTC{{tqDT.tqUTCOffsetString}}</p>  
    </div>

    <a class="sidebar-role ml-2" 
      title="Set active role..."
      routerLink="/profile" [queryParams]="{ tab: 'configuration' }">
      <!-- <mat-icon class="sidebar-icon">manage_accounts</mat-icon> -->
      {{TQroleSelectedCode}}
    </a>

    <!-- PADS LIST -->

    <ul class="list-unstyled mt-2">
      <li>
        <a class="sidebar-menu-item"
          [ngClass]="appState.lastPad=='todopad'?'current':''"
          title="Todopad (Alt-1)"
          routerLink="/todopad">
          <mat-icon class="sidebar-icon">today</mat-icon>
          Todopad
        </a>
      </li>
      <li>
        <a class="sidebar-menu-item"
          [ngClass]="appState.lastPad=='weekpad'?'current':''"
          title="Weekpad (Alt-2)"
          routerLink="/weekpad">
          <mat-icon class="sidebar-icon">date_range</mat-icon>
          Weekpad
        </a>
      </li>
      <li>
        <a class="sidebar-menu-item"
          [ngClass]="appState.lastPad=='monthpad'?'current':''"
          title="Monthpad (Alt-3)"
          routerLink="/monthpad">
          <mat-icon class="sidebar-icon">calendar_month</mat-icon>
          Monthpad
        </a>
      </li>
      <li>
        <a class="sidebar-menu-item"
          [ngClass]="appState.lastPad=='workpad'?'current':''"
          title="Workpad (Alt-4)"
          routerLink="/workpad">
          <mat-icon class="sidebar-icon">view_kanban</mat-icon>
          Workpad
        </a>
      </li>
      <hr>
      <li>
        <a class="sidebar-menu-item"
          [ngClass]="appState.lastPad=='notepad'?'current':''"
          title="Notepad (Alt-5)"
          routerLink="/notepad">
          <mat-icon class="sidebar-icon">text_snippet</mat-icon>
          Notepad
        </a>
      </li>
      <hr>
      <li>
        <a class="sidebar-menu-item"
          [ngClass]="appState.lastPad=='projectpad'?'current':''"
          title="Projects (Alt-6)"
          routerLink="/projectpad">
          <mat-icon class="sidebar-icon">folder</mat-icon>
          Projects
        </a>
      </li>
    </ul>

    <!-- PROJECTS TREE -->

    <ul *ngIf="appState.lastSubscription?.id != null"
      class="mat-container list-unstyled mt-1 mb-0 pl-2">
      <mat-tree 
        [dataSource]="treeSource" 
        [treeControl]="treeControl">
        <!-- Leaf nodes -->
        <mat-tree-node *matTreeNodeDef="let node">
          <div #projectLeaf>
            <fa-icon matTreeNodeToggle
              class="mat-tree-icon"
              [icon]="faCaretSquareRight"
            ></fa-icon>
            <li style="display:inline-block">
              <a class="ml-2"
                style="vertical-align: middle"
                [ngClass]="(node.id==appState.projectFilterId)?'current':''"
                (click)="setProjectTo(node.id, node.code, treeControl.isExpanded(node))"
                routerLink="/{{appState.lastPad}}"
                title="{{node.title}}">
                {{node.code}}
              </a>
            </li>          
          </div>
          <tq-project-menu *ngIf="!this.samApp.onMobile"
              [target]="projectLeaf"
              [canAddProject]="false"
              (command)="onProjectMenuCommand($event, node)"
            ></tq-project-menu>      
        </mat-tree-node>

        <!-- Expandable nodes -->
        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
          <div #projectNode>
            <fa-icon matTreeNodeToggle *ngIf="!treeControl.isExpanded(node)" 
              class="mat-tree-icon" 
              [icon]="faPlusSquare">
            </fa-icon>
            <fa-icon matTreeNodeToggle *ngIf="treeControl.isExpanded(node)" 
              class="mat-tree-icon" 
              [icon]="faMinusSquare">
            </fa-icon>
            <li 
              class="ml-2"
              style="display:inline-block"
            >
              <a style="vertical-align: middle"
                [ngClass]="(node.id==appState.projectFilterId)?'current':''"
                (click)="setProjectTo(node.id, node.code, treeControl.isExpanded(node))"
                routerLink="/{{appState.lastPad}}"
                title="{{node.title}}">
                {{node.code}}
              </a>
              <ul 
                [class.mat-tree-invisible]="!treeControl.isExpanded(node)"
              >
                <ng-container matTreeNodeOutlet>
                </ng-container>
              </ul>
            </li>
          </div>
          <tq-project-menu *ngIf="!this.samApp.onMobile"
            [target]="projectNode"
            [canAddProject]="false"
            (command)="onProjectMenuCommand($event, node)"
          ></tq-project-menu>      
        </mat-nested-tree-node>
      </mat-tree>
    </ul>

  </div>

  <!-- COLLAPSED SIDEBAR MENU -->

  <div *ngIf="appState.sidebarCollapsed" 
    class="mt-2 pt-5">

    <!-- PADS ICONS -->
    <ul class="list-unstyled mt-3">
      <li>
        <a
          [ngClass]="appState.lastPad=='todopad'?'current':''"
          title="Todopad (Alt-1)"
          routerLink="/todopad">
          <mat-icon class="sidebar-icon">today</mat-icon>
        </a>
      </li>
      <li>
        <a
          [ngClass]="appState.lastPad=='weekpad'?'current':''"
          title="Weekpad (Alt-2)"
          routerLink="/weekpad">
          <mat-icon class="sidebar-icon">date_range</mat-icon>
        </a>
      </li>
      <li>
        <a
          [ngClass]="appState.lastPad=='monthpad'?'current':''"
          title="Monthpad (Alt-3)"
          routerLink="/monthpad">
          <mat-icon class="sidebar-icon">calendar_month</mat-icon>
        </a>
      </li>
      <li>
        <a
          [ngClass]="appState.lastPad=='workpad'?'current':''"
          title="Workpad (Alt-4)"
          routerLink="/workpad">
          <mat-icon class="sidebar-icon">view_kanban</mat-icon>
        </a>
      </li>
    </ul>
    <ul class="list-unstyled mt-3">
      <li>
        <a
          [ngClass]="appState.lastPad=='notepad'?'current':''"
          title="Notepad (Alt-5)"
          routerLink="/notepad">
          <mat-icon class="sidebar-icon">text_snippet</mat-icon>
        </a>
      </li>
    </ul>
    <ul class="list-unstyled mt-3">
      <li>
        <a
          [ngClass]="appState.lastPad=='projectpad'?'current':''"
          title="Projects (Alt-6)"
          routerLink="/projectpad">
          <mat-icon class="sidebar-icon">folder</mat-icon>
        </a>
      </li>
    </ul>

  </div>

  <!-- Reserve space to deal with the fixed size footer -->
  <div class="samFooter"></div>
</nav>
