import { createReducer, on } from '@ngrx/store';

import { initialProjectsState } from './projects.state';
import * as PROJECTS_ACTIONS from './projects.actions';


export const projectsReducer = createReducer(
  initialProjectsState,

  // TQProjects
  on(PROJECTS_ACTIONS.selectProject, (state, { id }) => ({
    ...state,
    selectedProjectId: id,
  })),

  on(PROJECTS_ACTIONS.loadProjectsList, (state) => ({
    ...state,
    status: 'loading',
    error: null,
  })),
  on(PROJECTS_ACTIONS.loadProjectsListOK, (state, { projects }) => ({
    ...state,
    TQprojects: projects,
    status: 'loaded',
    error: null
  })),  
  on(PROJECTS_ACTIONS.loadProjectsListKO, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error,
  })),  

);