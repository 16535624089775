import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import * as TASKS_ACTIONS from './tasks.actions';

import { TQApiService } from 'src/app/services/tqapi.service';

@Injectable()
export class TasksEffects {

  constructor(
    private actions$: Actions,
    private tqApiService: TQApiService
  ) { }

  // TQtasks
  loadTasksList$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(TASKS_ACTIONS.loadTasksList),
          switchMap(async () => {
            try 
            {
              const TQtasks = await this.tqApiService.getTaskSamplesByProfile();
              return TASKS_ACTIONS.loadTasksListOK({ TQtasks });
            } 
            catch (error) 
            {
              TASKS_ACTIONS.loadTasksListKO({ error })
            }
          }
        )
        //   from(this.tqApiService.getTaskSamplesByProfile())
        //     .pipe(
        //       map( (TQtasks) => TASKS_ACTIONS.loadTasksListOK({ TQtasks })),
        //       catchError( (error) => of(TASKS_ACTIONS.loadTasksListKO({ error })))
        //     )
        // )
      )
    )

}