import { createSelector, createFeatureSelector } from '@ngrx/store';
import { NotesState } from './notes.state';


export const getNotesState = createFeatureSelector('notesState')

export const notesState = createSelector(
  getNotesState,
  (notesState: NotesState) => notesState
)


