import { NoteSample } from 'src/app/models/note';

export interface NotesState 
{
  status: string; // 'empty' | 'loading' | 'loaded' | 'error'
  error: string;

  TQnotes: Array<NoteSample>;
  canAddNote: boolean;
  selectedNoteId: number;
}

export const initialNotesState: NotesState = {
  status: 'empty',
  error: null,

  TQnotes: [],
  canAddNote: true, 
  selectedNoteId: 0, 
}