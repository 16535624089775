import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { DateTime } from 'luxon';
import { TQDateTimeService } from 'src/app/services/tqdatetime.service';


@Component({
  selector: 'tq-show-date-time-widget',
  templateUrl: './tq-show-date-time-widget.component.html',
  styleUrl: './tq-show-date-time-widget.component.scss'
})
export class TQShowDateTimeWidgetComponent 
{
  @Input() date: DateTime; 
  @Input() time: string; 
  @Input() dateFormat: string; 
  @Input() timeFormat: string = "24h";

  dateLabel: string = null;
  timeLabel: string = null;

  constructor
  (
    private tqDT: TQDateTimeService
  )
  {}

  ngOnInit()
  {
    if (this.date == null || this.time == null) return;
  }

  // Parent component changes 
  ngOnChanges() 
  {
    this.dateLabel = null;
    this.timeLabel = null;
    if (this.date == null || this.time == null) return;

    let datetime = this.tqDT.toLuxonFromISO(this.tqDT.formatDateISO(this.date) + "T" + this.time);

    this.dateLabel = this.tqDT.formatDate(datetime)
    this.timeLabel = this.tqDT.formatTime(datetime);
  } 
}
