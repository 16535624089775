import { Project } from "src/app/models/project";

export interface ProjectsState 
{
  status: string; // 'empty' | 'loading' | 'loaded' | 'error'
  error: string;

  TQprojects: Array<Project>;
  selectedProjectId: number;

  //task_status: 'empty' | 'loading' | 'loaded' | 'error'
  //task: Task; // working Task
}

export const initialProjectsState: ProjectsState = {
  status: 'empty',
  error: null,

  TQprojects: [],
  selectedProjectId: 0,
  
  //task_status: 'empty',
  //tasl: null
}