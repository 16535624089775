import { Component } from '@angular/core';

import { StripeService } from '../stripe.service';


@Component({
  selector: 'app-stripe-callback',
  templateUrl: './stripe-callback.component.html',
  styleUrls: ['./stripe-callback.component.scss']
})
export class StripeCallbackComponent {

  constructor
  (
    private stripe: StripeService
  ){}

  ngOnInit()
{  
    this.stripe.handleStripeCallback();
  }

}
