import { createReducer, on } from '@ngrx/store';

import { initialAppState } from './app.state';
import  * as ACTIONS from './app.actions' ; 

import { DateTime } from 'luxon';


export const appReducer = createReducer(
  initialAppState,

  on(ACTIONS.applicationLoading, (state) => ({
    ...state,
    status: 'loading',
  })),
  on(ACTIONS.applicationLoaded, (state) => ({
    ...state,
    status: 'loaded',
  })),

  on(ACTIONS.sidebarActive, (state, { value }) => ({
    ...state,
    sidebarActive: value,
  })),
  on(ACTIONS.sidebarCollapsed, (state, { value }) => ({
    ...state,
    sidebarCollapsed: value,
  })),

  on(ACTIONS.onMobile, (state, { value }) => ({
    ...state,
    onMobile: value,
  })),

  on(ACTIONS.enterTQpad, (state, { pad }) => ({
    ...state,
    lastPad: pad,
    nextPad: pad
  })),

  on(ACTIONS.nextTQpad, (state, { pad }) => ({
    ...state,
    nextPad: pad
  })),

  on(ACTIONS.projectFiltered, (state, { id, code, color, list }) => ({
    ...state,
    projectFilterId: id,
    projectFilterCode: code,
    projectFilterColor: color,
    projectFilterList: list,
  })),

  on(ACTIONS.setLastGuardTime, (state, {}) => ({
    ...state,
    lastGuardTime: DateTime.now(),
  })),

  on(ACTIONS.setTQApiToken, (state, { token }) => ({
    ...state,
    TQApiToken: token,
  })),

  on(ACTIONS.newAuth0Token, (state, { token }) => ({
    ...state,
    Auth0Token: token,
 })),

  on(ACTIONS.setAuth0Profile, (state, { profile }) => ({
    ...state,
    Auth0Profile: profile,
  })),

  on(ACTIONS.setTQprofileId, (state, { id }) => ({
    ...state,
    TQprofileId: id,
  })),

  on(ACTIONS.setTQprofileEmail, (state, { email }) => ({
    ...state,
    TQprofileEmail: email,
  })),

  on(ACTIONS.setTQprofilePrefs, (state, { profile }) => ({ 
    ...state,
    prefLoginStartPad          : profile['prefLoginStartPad'],
    prefLogoutAfterInactiveFor : profile['prefLogoutAfterInactiveFor'],
    prefLocTimeZone            : profile['prefLocTimeZone'],
    prefLocWeekStart           : profile['prefLocWeekStart'],
    prefLocWeekendDays         : profile['prefLocWeekendDays'],
    prefLocDateFormat          : profile['prefLocDateFormat'],
    prefLocTimeFormat          : profile['prefLocTimeFormat'],
    prefWorkpadNumCols         : profile['prefWorkpadNumCols'],
    prefWorkpadCol1            : profile['prefWorkpadCol1'],
    prefWorkpadCol2            : profile['prefWorkpadCol2'],
    prefWorkpadCol3            : profile['prefWorkpadCol3'],
    prefWorkpadCol4            : profile['prefWorkpadCol4'],
    prefWorkpadCol5            : profile['prefWorkpadCol5'],
    prefWorkpadCol1Mobile      : profile['prefWorkpadCol1Mobile'],
    prefWorkpadCol2Mobile      : profile['prefWorkpadCol2Mobile'],
    prefWorkpadCol3Mobile      : profile['prefWorkpadCol3Mobile'],
    prefWorkpadCol4Mobile      : profile['prefWorkpadCol4Mobile'],
    prefWorkpadCol5Mobile      : profile['prefWorkpadCol5Mobile'],
    prefTaskRadarEnabled       : profile['prefTaskRadarEnabled'],
    prefTaskRadarFreq          : profile['PrefTaskRadarFreq'],
    prefTaskRadarRange         : profile['prefTaskRadarRange'],

    cfgRoleSelectedId          : profile['cfgRoleSelectedId'],
    default_project_id         : profile['default_project_id'],
  })),

  on(ACTIONS.setTQroleSelectedId, (state, { id }) => ({
    ...state,
    TQroleSelectedId: id,
  })),

  on(ACTIONS.setTQroles, (state, { roles }) => ({
    ...state,
    TQroles: roles,
  })),

  on(ACTIONS.setTQproductPromotions, (state, { promos }) => ({
    ...state,
    TQproductPromotions: promos,
  })),

  on(ACTIONS.setLastSubscription, (state, { subscription }) => ({
    ...state,
    lastSubscription: subscription,
  })),


);